import { RootState } from '@app/store';
import { CNRState } from '@app/store/data/Cnr';
import { MetadataState } from '@app/store/data/Metadata';
import { PositionAccuracyState } from '@app/store/data/PositionAccuracy';
import { SatelliteClockAccuracyState } from '@app/store/data/SatelliteClockAccuracy';
import { SatLibState } from '@app/store/data/SatLibrary';
import { StationsState } from '@app/store/data/Stations';
import { TECState } from '@app/store/data/Tec';
import { TLEState } from '@app/store/data/Tle';
import { getDataState } from '@app/store/globals';

/* **************************************** *
 *    Export globalized state selectors     *
 * **************************************** */
export const getTLEState = (state: RootState): TLEState => getDataState(state).tle;
export const getStationsState = (state: RootState): StationsState => getDataState(state).stations;
export const getCNRState = (state: RootState): CNRState => getDataState(state).cnr;
export const getTECState = (state: RootState): TECState => getDataState(state).tec;
export const getSatLibState = (state: RootState): SatLibState => getDataState(state).satLib;
export const getPositionAccuracyState = (state: RootState): PositionAccuracyState =>
  getDataState(state).positionAccuracy;
export const getSatelliteClockAccuracyState = (state: RootState): SatelliteClockAccuracyState =>
  getDataState(state).satelliteClockAccuracy;
export const getMetadataState = (state: RootState): MetadataState => getDataState(state).metadata;
