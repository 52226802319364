export const degreesToRadians = (degree: any) => (degree / 180) * Math.PI;

export const precise = (x: string | number | undefined, precision = 5): string => {
  let N;

  if (x === undefined) {
    return '';
  }

  if (typeof x === 'string') {
    N = Number.parseFloat(x);
  } else {
    N = Number(x);
  }

  return N.toPrecision(precision);
};

export const fixed = (x: string | number | undefined, fixed = 2): string => {
  let N;

  if (x === undefined) {
    return '';
  }

  if (typeof x === 'string') {
    N = Number.parseFloat(x);
  } else {
    N = Number(x);
  }

  return N.toFixed(fixed);
};

export const latLonToDecimal = (value: any) => {
  let sign = 1;
  if (value < 0) {
    value *= -1;
    sign = -1;
  }

  const degree = Math.floor(value / 10000);
  const remain = value % 10000;
  const minutes = Math.floor(remain / 100);
  const seconds = remain % 100;

  return sign * (degree + minutes / 60 + seconds / 3600);
};

export const toDecimal = (value: number): number => {
  let sign = 1;
  if (value < 0) {
    value *= -1;
    sign = -1;
  }
  const degree = Math.floor(value / 10000);
  const remain = value % 10000;
  const minutes = Math.floor(remain / 100);
  const seconds = remain % 100;
  const decimal = sign * (degree + minutes / 60 + seconds / 3600);

  return decimal;
};
