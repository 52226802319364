import { Theme } from '@mui/material';

const appStyles = {
  root: {
    flexGrow: 1,
    padding: 0,
    /* fontSize: 13, */
    color: 'primary.main',
    backgroundColor: 'white',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  menu: (theme: Theme) => ({
    width: `${theme.menuWidth}rem`,
  }),
  header: (theme: Theme) => ({
    height: `${theme.headerHeight}rem`,
    width: '100%',
    color: theme.palette.primary.main,
    position: 'relative',
    flexWrap: 'nowrap',
    boxShadow: '-2px -3px 8px rgba(0, 0, 0, .5)',
    '& .dlr-logo': {
      height: `calc(${theme.headerHeight}rem * 0.75)`,
    },
    '& .sigpermon-logo': {
      height: `calc(${theme.headerHeight}rem * 0.85)`,
    },
    '& img.gk-signature': {
      height: `calc(${theme.headerHeight}rem * 0.6)`,
    },
  }),
  main: (theme: Theme) => ({
    height: `calc(100vh - ${theme.headerHeight}rem)`,
    width: '100vw',
    borderColor: 'red',
    borderWidth: '1px 0 1px 0',
    borderWeight: 'solid',
    overflowY: 'auto',
  }),
  content: (theme: Theme) => ({
    minHeight: `calc(100vh - ${theme.headerHeight}rem -  ${theme.footerHeight}rem)`,
    alignContent: 'start',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  }),
  settings: {
    width: '100%',
    height: '100%',
    paddingLeft: '1.5em', // TODO: This should cause automatic alignment with "Einstellungen"
    paddingRight: '1.5em',
  },
  paper: {
    padding: 0,
    textAlign: 'center',
    color: 'text.secondary',
  },
  mainTitle: {
    fontSize: '2em',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  breadCrumbs: {
    paddingTop: '1em',
    color: 'text.secondary',
  },
  breadCrumbsActive: {
    color: 'text.primary',
  },
  fullheight: {
    height: '100vh',
  },
  dropdownStyle: {
    border: '1px solid white',
    borderRadius: 4,
    backgroundColor: '#003968',
  },
  formControl: {
    minWidth: '100% !important',
  },
  menuPaper: {
    maxHeight: '50%',
  },
  footer: (theme: Theme) => ({
    fontSize: `${theme.footerHeight * 0.5}rem`,
    height: `${theme.footerHeight}rem`,
    backgroundColor: theme.palette.grey[200],
  }),
};

export default appStyles;
