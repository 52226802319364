import { setSelectedSatellite, setSelectedStation } from './Settings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getAccordionsState } from '@app/store/globals';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface AccordionsState {
  stationInfoExpanded: boolean;
  dopValuesExpanded: boolean;
  satelliteListExpanded: boolean;
  satelliteInfoExpanded: boolean;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */
const setSatteliteSelected = (state: AccordionsState, satIsSelected: boolean) => {
  state.satelliteInfoExpanded = satIsSelected;

  return state;
};

const setStationSelected = (state: AccordionsState, stationIsSelected: boolean) => {
  state.stationInfoExpanded = stationIsSelected;
  state.dopValuesExpanded = stationIsSelected;
  // state.satelliteListExpanded = stationIsSelected;

  return state;
};

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: AccordionsState = {
  stationInfoExpanded: false,
  dopValuesExpanded: false,
  satelliteListExpanded: false,
  satelliteInfoExpanded: true,
};

const accordionsSlice = createSlice({
  name: 'accordions',
  initialState,
  reducers: {
    setStationInfoExpanded: (state: AccordionsState, action: PayloadAction<boolean>) => {
      state.stationInfoExpanded = action.payload;
    },
    setDopValuesExpanded: (state: AccordionsState, action: PayloadAction<boolean>) => {
      state.dopValuesExpanded = action.payload;
    },
    setSatelliteListExpanded: (state: AccordionsState, action: PayloadAction<boolean>) => {
      state.satelliteListExpanded = action.payload;
    },
    setSatelliteInfoExpanded: (state: AccordionsState, action: PayloadAction<boolean>) => {
      state.satelliteInfoExpanded = action.payload;
    },
  },

  extraReducers: {
    [setSelectedSatellite.type]: (state: AccordionsState) => {
      state = setSatteliteSelected(state, true);
      state = setStationSelected(state, true);

      return state;
    },
    [setSelectedStation.type]: (state: AccordionsState, action: any) => {
      state = setSatteliteSelected(state, true);
      state = setStationSelected(state, action.payload !== '');

      return state;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const getStationInfoExpanded = (state: RootState) =>
  getAccordionsState(state).stationInfoExpanded;
export const getDopValuesExpanded = (state: RootState) =>
  getAccordionsState(state).dopValuesExpanded;
export const getSatelliteListExpanded = (state: RootState) =>
  getAccordionsState(state).satelliteListExpanded;
export const getSatelliteInfoExpanded = (state: RootState) =>
  getAccordionsState(state).satelliteInfoExpanded;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const {
  setStationInfoExpanded,
  setDopValuesExpanded,
  setSatelliteListExpanded,
  setSatelliteInfoExpanded,
} = accordionsSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default accordionsSlice.reducer;
