import { getTLEState } from './globals';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';

import { getSocket } from '@app/services/Socket';
import { createSocketPromiseSync } from '@app/services/SocketPromiseWrapper';

import { Satellite, SatelliteSystem } from '@app/models';

import { mjdFromUnixMilliseconds } from '@app/utils/Datetime';
import { getSatellites } from '@app/utils/Dop';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export type SystemRecords = Record<string, Satellite[]>;

export interface Systems {
  [SatelliteSystem.GALILEO]: SystemRecords;
  [SatelliteSystem.GPS]: SystemRecords;
  [SatelliteSystem.GLONASS]: SystemRecords;
  [SatelliteSystem.BEIDOU]: SystemRecords;
}

export type TLEState = Systems;

interface TLEResponse {
  GPS: any;
  GALILEO: any;
  BEIDOU: any;
  GLONASS: any;
}

export interface SystemRecord {
  [SatelliteSystem.GALILEO]: Satellite[];
  [SatelliteSystem.GPS]: Satellite[];
  [SatelliteSystem.GLONASS]: Satellite[];
  [SatelliteSystem.BEIDOU]: Satellite[];
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */
/*
  Constants
*/
const TLE_VALID_PERIOD = 14;
const TLE_LOAD_PERIOD = 15;

/**
 * Async action to query tle's from database
 */
export const queryTLE = createAsyncThunk('data/tle/query', async (args, thunkApi) => {
  const socket = getSocket();
  const socketPromise = createSocketPromiseSync(socket);

  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  const response = await socketPromise<TLEResponse>(
    'getTLE',
    {
      mjd_start: Math.floor(mjdFromUnixMilliseconds(new Date().getTime())) - TLE_LOAD_PERIOD,
      mjd_end: Math.floor(mjdFromUnixMilliseconds(new Date().getTime())),
    },
    60000,
  ); // TODO useful timeout

  thunkApi.dispatch(setRecordMap(response));

  return response;
});

/**
 * Function to select valid tle's for a given timestamp
 *
 * @param {Systems} systems
 * @param {integer} timestamp
 *
 * @returns {map} Filtered systems
 */
export function selectTLEsByTimestamp(systems: Systems, timestamp: number) {
  const keys = Object.keys(systems.GPS);
  const last = parseInt(keys[keys.length - 1], 10);

  let currentMJD = Math.floor(mjdFromUnixMilliseconds(timestamp));

  if (last < currentMJD) {
    currentMJD = last;
  }

  return {
    [SatelliteSystem.GALILEO]: systems[SatelliteSystem.GALILEO][String(currentMJD)] || null,
    [SatelliteSystem.GPS]: systems[SatelliteSystem.GPS][String(currentMJD)] || null,
    [SatelliteSystem.GLONASS]: systems[SatelliteSystem.GLONASS][String(currentMJD)] || null,
    [SatelliteSystem.BEIDOU]: systems[SatelliteSystem.BEIDOU][String(currentMJD)] || null,
  };
}

/**
 * Function to calculate valid tle's from an array of tle's for defined date.
 * Requires tle's to be sorted by mjd descending
 *
 * @param {array} system list of tle rows
 *                       elements are objects with props:
 *                       [name, tle_line_1, tle_line_2, mjd]
 * @param {integer} day (modified julian date)
 *
 * @returns {array} filteredTLEArray
 */
function buildTLERecordPerDay(system: any, day: number) {
  const seen = {};

  return system.filter((row: any) => {
    const name = row[0];
    const mjd = row[3];

    if (mjd > day || mjd < day - TLE_VALID_PERIOD) {
      // check if current tle is in the valid date range
      return false;
    }

    if (Object.prototype.hasOwnProperty.call(seen, name)) {
      // check if we already found a more recent tle than the current one
      // !caution: uses object name to uniquely identify satellites
      return false;
    }

    seen[name] = true;

    return true; // this element fits the date range and we have not seen a more recent one, therefore, this is the one we want
  });
}

/**
 * Function to build tle map by mjds
 *
 * @param {Systems} systems Satellite Systems records
 * @param {number} mjd_start Modified julian date
 * @param {number} mjd_end Modified julian date
 *
 * @returns {Systems} Bundled tle's
 */
export function buildTLEStore(systems: TLEResponse, mjd_start: number, mjd_end: number): TLEState {
  const [GALILEO, GPS, GLONASS, BEIDOU] = [{}, {}, {}, {}] as [
    SystemRecords,
    SystemRecords,
    SystemRecords,
    SystemRecords,
  ];

  for (let day = mjd_start; day <= mjd_end; ++day) {
    GPS[String(day)] = getSatellites(SatelliteSystem.GPS, buildTLERecordPerDay(systems.GPS, day));
    GALILEO[String(day)] = getSatellites(
      SatelliteSystem.GALILEO,
      buildTLERecordPerDay(systems.GALILEO, day),
    );
    GLONASS[String(day)] = getSatellites(
      SatelliteSystem.GLONASS,
      buildTLERecordPerDay(systems.GLONASS, day),
    );
    BEIDOU[String(day)] = getSatellites(
      SatelliteSystem.BEIDOU,
      buildTLERecordPerDay(systems.BEIDOU, day),
    );
  }

  return {
    [SatelliteSystem.GALILEO]: GALILEO,
    [SatelliteSystem.GPS]: GPS,
    [SatelliteSystem.GLONASS]: GLONASS,
    [SatelliteSystem.BEIDOU]: BEIDOU,
  };
}

export function selectedSatellites(systems: SystemRecord, systemsEnabled: SatelliteSystem[]) {
  const satellites: Satellite[] = [];

  systemsEnabled.forEach((s) => {
    if (systems[s]) {
      satellites.push(...systems[s]);
    }
  });

  return satellites;
}

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: TLEState = {
  [SatelliteSystem.GALILEO]: {},
  [SatelliteSystem.GPS]: {},
  [SatelliteSystem.GLONASS]: {},
  [SatelliteSystem.BEIDOU]: {},
};

const tleSlice = createSlice({
  name: 'tle',
  initialState,
  reducers: {
    setRecordMap: (state: TLEState, action: PayloadAction<TLEResponse>) => {
      const newState = buildTLEStore(
        action.payload,
        Math.floor(mjdFromUnixMilliseconds(new Date().getTime())) - TLE_LOAD_PERIOD,
        Math.floor(mjdFromUnixMilliseconds(new Date().getTime())),
      );

      return newState;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const selectAllTLEs = (state: RootState) => getTLEState(state);

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setRecordMap } = tleSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default tleSlice.reducer;
