import React from 'react';

import { Box, Container, Paper, Typography } from '@mui/material';

const styles = {
  page: {
    marginBlock: 2,
  },
  paper: {
    padding: 0,
  },
  box: {
    padding: 3,
  },
  pageTitle: {
    marginBlock: 3,
  },
  pageImage: {
    marginBlock: 0,
    width: '20%',
    float: 'right',
  },
  image: {
    borderBottomLeftRadius: '35px',
  },
};

interface StandardPageProps {
  title?: string;
  imageSrc?: string;
  children: React.ReactNode;
}

const StandardPage = ({ title, imageSrc, children }: StandardPageProps) => {
  return (
    <Container sx={styles.page}>
      <Paper sx={styles.paper}>
        {imageSrc ? (
          <Box sx={styles.pageImage}>
            <img src={imageSrc} alt="Page icon" style={styles.image} />
          </Box>
        ) : null}
        <Box sx={styles.box}>
          {title ? (
            <Typography
              sx={styles.pageTitle}
              variant="h4"
              component="h4"
              align="left"
              color="primary"
            >
              {title}
            </Typography>
          ) : null}

          {children}
        </Box>
      </Paper>
    </Container>
  );
};

export default StandardPage;
