import * as mathjs from 'mathjs';
import {
  degreesToRadians,
  ecfToLookAngles,
  eciToEcf,
  eciToGeodetic,
  geodeticToEcf,
  gstime,
  propagate,
  twoline2satrec,
} from 'satellite.js';

// import { ecefToEnu } from '@app/utils/CoordinateSystems';
import {
  Position,
  Satellite,
  SatelliteLibraryItem,
  SatelliteStatus,
  SatelliteStatusChanges,
  SatelliteStatusItem,
  SatelliteSystem,
  VisibleSatellite,
} from '@app/models';

import * as vec from '@app/utils/Vector';
import { checkSatelliteStatus, getSatStatusFromPRN } from '@app/utils/SatelliteStatus';

/**
 * Converts unix milliseconds to the modified julian date
 * Use this conversion with caution, it desregards leap seconds
 * @category Utils
 * @param {any} milliseconds
 * @returns modified julian date
 */
export const mjdFromUnixMilliseconds = (milliseconds: any) => milliseconds / 86400000.0 + 40587.0;

/**
 * creates an object from the given parameters:
 * @category Utils
 * @function
 * @param {SatelliteSystem} constellation
 * @param {string} id
 * @param {string} satrec
 * @returns {SatelliteSystem} constellation
 * @returns {string} id
 * @returns {string} satrec
 */
export const satellite = (constellation: any, id: any, satrec: any) => ({
  constellation,
  id,
  satrec,
});
/**
 * satPosInertial creates an object from the given parameters:
 * @category Utils
 * @param eci
 * @param velocity
 * @param timestamp
 * @param gmst
 * @returns satPosInertial
 */
export const satPosInertial = (eci: any, velocity: any, timestamp: any, gmst: any) => ({
  eci,
  velocity,
  timestamp,
  gmst,
});

/**
 * positionGd creates an object from the given parameters:
 * @category Utils
 * @param longitude
 * @param latitude
 * @param height
 * @returns {number} longitude
 * @returns {number} latitude
 * @returns {number} height
 */
export const positionGd = (longitude: number, latitude: number, height: number): Position => ({
  longitude: degreesToRadians(longitude),
  latitude: degreesToRadians(latitude),
  height,
});

/**
 * @category Utils
 * @function
 * @param position
 * @returns {number} longitude
 * @returns {number} latitude
 * @returns {number} height/1000.0
 */
export const positionGdKm = (position: Position): Position => ({
  longitude: position.longitude,
  latitude: position.latitude,
  height: position.height === undefined ? 0 : position.height / 1000.0,
});

export const satPosFixed = (ecf: any, enu: any, lookAngles: any, obsGd: Position, latlng: any) => ({
  ecf,
  enu,
  lookAngles,
  obsGd,
  latlng,
});

export const dop = (vdop: number, tdop: number, hdop: number, pdop: number, gdop: number) => ({
  vdop,
  tdop,
  hdop,
  pdop,
  gdop,
});

function lookupGlonassSlot(satId: string): string | null {
  switch (satId) {
    case '730':
      return 'R01';
    case '747':
      return 'R02';
    case '744':
      return 'R03';
    case '759':
      return 'R04';
    case '756':
      return 'R05';
    case '733':
      return 'R06';
    case '745':
      return 'R07';
    case '743':
      return 'R08';
    case '702K':
      return 'R09';
    case '723':
      return 'R10';
    case '705K':
      return 'R11';
    case '758':
      return 'R12';
    case '721':
      return 'R13';
    case '752':
      return 'R14';
    case '757':
      return 'R15';
    case '761':
      return 'R16';
    case '751':
      return 'R17';
    case '754':
      return 'R18';
    case '720':
      return 'R19';
    case '719':
      return 'R20';
    case '755':
      return 'R21';
    case '706K':
      return 'R22';
    case '732':
      return 'R23';
    case '760':
      return 'R24';
    default:
      return null;
  }
}

/**
 * constructor of the gnssConstellation class
 *
 * @param {String} constellation (GPS, GALILEO, GLONASS, BEIDOU)
 * @param {array} tleArray (list of TLEs of the last seven days prior to timestamp)
 *
 * @returns {array} filteredSatPos_
 */
export function getSatellites(constellation: SatelliteSystem, tleArray: any) {
  const satRecords: Satellite[] = [];

  tleArray.forEach((element: string[]) => {
    const record = twoline2satrec(element[1], element[2]);

    let id = element[0];
    const tmp = element[0].match('[^\\(]+\\(([^\\)]+)\\)');

    let prn: string = tmp === null || tmp.length < 2 ? element[0].slice(-3) : tmp[1].toString();

    if (constellation === SatelliteSystem.GPS) {
      prn = `G${prn.slice(-2)}`;
    }
    if (constellation === SatelliteSystem.GALILEO) {
      prn = prn.slice(-3);

      if (prn === '223' && record.satnum === '49809') {
        prn = 'E34';
        id += ` (PRN ${prn})`;
      }

      if (prn === '224' && record.satnum === '49810') {
        prn = 'E10';
        id += ` (PRN ${prn})`;
      }
    }
    if (constellation === SatelliteSystem.GLONASS) {
      const lookup = lookupGlonassSlot(prn);
      if (lookup === null) {
        return;
      }
      prn = lookup;
    }
    if (constellation === SatelliteSystem.BEIDOU) {
      if (record.satnum === '36287') {
        // do not add this satellite to the list
        return;
      }

      switch (record.satnum) {
        case '44231':
          prn = 'C01';
          id += ` (${prn})`;
          break;
        case '44709':
          prn = 'C40';
          id += ` (${prn})`;
          break;
        case '44864':
          prn = 'C41';
          id += ` (${prn})`;
          break;
        case '44865':
          prn = 'C42';
          id += ` (${prn})`;
          break;
        case '44793':
          prn = 'C43';
          id += ` (${prn})`;
          break;
        case '44794':
          prn = 'C44';
          id += ` (${prn})`;
          break;
        case '40938':
          prn = 'C56';
          id = id.replace('(C18)', '(C56)');
          break;
        case '45344':
          prn = 'C60';
          id += ` (${prn})`;
          break;
        case '45807':
          prn = 'C61';
          id += ` (${prn})`;
          break;
        default:
      }
    }

    satRecords.push({
      id,
      prn,
      constellation,
      record,
    });
  });

  return satRecords;
}

export function filterUnhealthyVis(
  satStatusArray: SatelliteStatusItem[],
  visSatRecords: VisibleSatellite[],
  timestamp: Date,
): VisibleSatellite[] {
  const filteredSatellites: VisibleSatellite[] = [];

  visSatRecords.forEach((element) => {
    if (getSatStatusFromPRN(satStatusArray, element.prn, timestamp) === SatelliteStatus.HEALTHY) {
      filteredSatellites.push(element);
    }
  });

  return filteredSatellites;
}

export function filterUnhealthyVisFromDatabase(
  satStatusArray: SatelliteStatusItem[],
  satStatusChanges: SatelliteStatusChanges,
  satLib: SatelliteLibraryItem[],
  visSatRecords: VisibleSatellite[],
  timestamp: Date,
): VisibleSatellite[] {
  const filteredSatellites: VisibleSatellite[] = [];

  visSatRecords.forEach((element) => {
    const satelliteStatus = checkSatelliteStatus(
      element.prn,
      new Date(timestamp).toISOString(),
      satStatusArray,
      satLib,
      satStatusChanges,
    );

    if (satelliteStatus === SatelliteStatus.HEALTHY) {
      filteredSatellites.push(element);
    }
  });

  return filteredSatellites;
}

export function filterUnhealthy(
  satStatusArray: SatelliteStatusItem[],
  satRecords: Satellite[],
  timestamp: Date,
): Satellite[] {
  const filteredSatellites: Satellite[] = [];

  satRecords.forEach((element) => {
    if (getSatStatusFromPRN(satStatusArray, element.prn, timestamp) === SatelliteStatus.HEALTHY) {
      filteredSatellites.push(element);
    }
  });

  return filteredSatellites;
}

export function filterUnhealthyFromDatabase(
  satStatusArray: SatelliteStatusItem[],
  satStatusChanges: SatelliteStatusChanges,
  satLib: SatelliteLibraryItem[],
  satRecords: Satellite[],
  timestamp: Date,
): Satellite[] {
  const filteredSatellites: Satellite[] = [];

  satRecords.forEach((element) => {
    const satelliteStatus = checkSatelliteStatus(
      element.prn,
      new Date(timestamp).toISOString(),
      satStatusArray,
      satLib,
      satStatusChanges,
    );

    if (satelliteStatus === SatelliteStatus.HEALTHY) {
      filteredSatellites.push(element);
    }
  });

  return filteredSatellites;
}

/**
 * Calculate eci position and velocity for given timestamp
 *
 * @param {Array} satRecords
 * @param {Date} timestamp
 *
 * @returns {Array} satPosEci (eci position and velocity, timestamp, gmst)
 */
export function getPositionAndVelocity(satRecords: Satellite[], timestamp: Date) {
  const gmst = gstime(timestamp);
  const satPosEci: {
    eci: any;
    velocity: any;
    timestamp: any;
    gmst: any;
  }[] = [];
  for (let i = 0; i < satRecords.length; i++) {
    const satrec = { ...satRecords[i].record };
    const positionAndVelocity = propagate(satrec, timestamp);
    satPosEci.push(
      satPosInertial(positionAndVelocity.position, positionAndVelocity.velocity, timestamp, gmst),
    );
  }
  return satPosEci;
}
/**
 * retrieves list of visible satellites according to position of observer
 *
 * @param {Array} satPosEci
 * @param {Float} longitude  [deg]
 * @param {Float} latitude   [deg]
 * @param {Float} height     [m]
 *
 * @returns {array} filteredSatPos_
 */
export function getVisibleSatellites(
  satRecords: any,
  satPosEci: any,
  longitude: number,
  latitude: number,
  height: number,
  elevationThreshold: number,
  filterBelowHorizon = true,
): VisibleSatellite[] {
  const satellites: VisibleSatellite[] = [];
  const obsGd = positionGd(longitude, latitude, height); // height should be stored as m (si) for data exchange
  const obsGdKm = positionGdKm(obsGd); // height converted to km for use in satellite.js

  let elevThrRad = 0;

  if (filterBelowHorizon) {
    elevThrRad = degreesToRadians(elevationThreshold);
  }

  for (let i = 0; i < satPosEci.length; i++) {
    const satposeci = { ...satPosEci[i] };
    const ecf = eciToEcf(satposeci.eci, satposeci.gmst);

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'EcfVec3<unknown>' is not assigna... Remove this comment to see the full error message
    const lookangles = ecfToLookAngles(obsGdKm, ecf);

    if (filterBelowHorizon && lookangles.elevation < elevThrRad) {
      continue;
    }

    const latlng = eciToGeodetic(satposeci.eci, satposeci.gmst);
    /*    const enu = ecefToEnu(
          (ecf as any).x * 1000,
          (ecf as any).y * 1000,
          (ecf as any).z * 1000,
          latitude,
          longitude,
          height,
        ); */

    satellites.push({
      ...satRecords[i],
      name: satRecords[i].id,
      prn: satRecords[i].prn,
      position: {
        latitude: latlng.latitude,
        longitude: latlng.longitude,
        height: latlng.height,
      },
      lookAngles: lookangles,
      obsGd,
      ecf,
      //      enu,
    });
  }

  return satellites;
}

/**
 * Computes the Dilution of Precision Values
 *
 * This function does not perform the costly ENU conversion. This is no problem
 * as DOPs are coordinate system independent. Only x/y dop value depend on it,
 * hence, the computation is omitted.
 *
 * @category Utils
 * @param {float} lat Latitude of observer [degree]
 * @param {float} lon Longitude of observer [degree]
 * @param {float} lat Height of observer [meter]
 * @param {*} satellitePositionArrayECF Array of satellite positions in ECF system
 */
export function fastDOPFromGeodetic(
  lat: number,
  lon: number,
  height: number,
  satellitePositionArrayECF: any,
  elevationMask: number,
  filterBelowHorizon = true,
) {
  const observerEcf = geodeticToEcf({
    longitude: degreesToRadians(lon),
    latitude: degreesToRadians(lat),
    height: height / 1000, // to km
  });
  const obsGd = positionGd(lon, lat, height);

  return fastDOPFromECF(
    observerEcf,
    satellitePositionArrayECF,
    elevationMask,
    positionGdKm(obsGd),
    filterBelowHorizon,
  );
}
/**
 * Computes the Dilution of Precision Values
 *
 * This function does not perform the costly ENU conversion. This is no problem
 * as DOPs are coordinate system independent. Only x/y dop value depend on it,
 * hence, the computation is omitted.
 *
 * @category Utils
 * @param {vec} observerEcf Position of the receiver in ECF coordinates
 * @param {vec[]} satellitePositionArrayECF Array of satellite positions in ECF system
 */
export function fastDOPFromECF(
  observerEcf: any,
  satellitePositionArrayECF: any,
  elevationMask: number,
  obsGdKm: Position,
  filterBelowHorizon = true,
) {
  let nSatelliteVisible = 0;
  // Matrix A^T * A
  const m = mathjs.matrix(mathjs.zeros(4, 4), 'dense');
  const mdata = (m as any)._data;

  let elevationThreshold = 0;

  if (filterBelowHorizon) {
    elevationThreshold = Math.sin(degreesToRadians(elevationMask));
  }

  const observerEcfNormalized = {
    x: observerEcf.x,
    y: observerEcf.y,
    z: observerEcf.z,
  };

  vec.normalize(observerEcfNormalized);

  for (let iSatellite = 0; iSatellite < satellitePositionArrayECF.length; ++iSatellite) {
    const satPositionECF = satellitePositionArrayECF[iSatellite];
    const lineOfSight = vec.difference(satPositionECF, observerEcf);
    vec.normalize(lineOfSight);

    if (filterBelowHorizon) {
      // only use visible sattelites with an elevation  >= elevationMask
      if (vec.dot(lineOfSight, observerEcfNormalized) < elevationThreshold) {
        continue;
      }
    }

    // unrolled for loop mdata[i][j] += lineOfSight[i]*lineOfSight[j]
    mdata[0][0] += lineOfSight.x * lineOfSight.x;
    mdata[0][1] += lineOfSight.x * lineOfSight.y;
    mdata[0][2] += lineOfSight.x * lineOfSight.z;
    mdata[0][3] -= lineOfSight.x;
    mdata[1][0] += lineOfSight.y * lineOfSight.x;
    mdata[1][1] += lineOfSight.y * lineOfSight.y;
    mdata[1][2] += lineOfSight.y * lineOfSight.z;
    mdata[1][3] -= lineOfSight.y;
    mdata[2][0] += lineOfSight.z * lineOfSight.x;
    mdata[2][1] += lineOfSight.z * lineOfSight.y;
    mdata[2][2] += lineOfSight.z * lineOfSight.z;
    mdata[2][3] -= lineOfSight.z;
    mdata[3][0] -= lineOfSight.x;
    mdata[3][1] -= lineOfSight.y;
    mdata[3][2] -= lineOfSight.z;
    mdata[3][3] += 1;
    nSatelliteVisible++;
  } // end for satellites
  // The system martix can only be inverted, if  the number of satellites is >= 4
  if (nSatelliteVisible < 4) {
    return undefined;
  }

  const minv = mathjs.inv(m);

  // Calculate DOP
  // Note: x / y dops are dependent of the coordinate system and are therefore not correct, since we are not in ENU
  // const xdop = Math.sqrt(minv.get([0, 0]));
  // const ydop = Math.sqrt(minv.get([1, 1]));
  const tdop = Math.sqrt(minv.get([3, 3]));
  const pdop = Math.sqrt(minv.get([0, 0]) + minv.get([1, 1]) + minv.get([2, 2]));
  const gdop = Math.sqrt(minv.get([0, 0]) + minv.get([1, 1]) + minv.get([2, 2]) + minv.get([3, 3]));

  // Note: vdop and hdop can't be calculated in ecef but in enu
  // const vdop = Math.sqrt(minv.get([2, 2]));
  // const hdop = Math.sqrt(minv.get([0, 0]) + minv.get([1, 1]));

  // conversion to enu
  const sinLambda = Math.sin(obsGdKm.latitude);
  const sinPhi = Math.sin(obsGdKm.longitude);
  const cosLambda = Math.cos(obsGdKm.latitude);
  const cosPhi = Math.cos(obsGdKm.longitude);

  // rotation matrix
  const r_enu = mathjs.matrix(
    [
      [-sinPhi, -sinLambda * cosPhi, cosPhi * cosLambda],
      [cosPhi, -sinLambda * sinPhi, cosLambda * sinPhi],
      [0, cosLambda, sinLambda],
    ],
    'dense',
  );

  // q_ecef is a subset of minv rows 0..2, columns 0..2
  const q_ecef = mathjs.subset(minv, mathjs.index(mathjs.range(0, 3), mathjs.range(0, 3)));
  const q_enu = mathjs.multiply(mathjs.multiply(mathjs.transpose(r_enu), q_ecef), r_enu);

  const vdop = Math.sqrt(q_enu.get([2, 2]));
  const hdop = Math.sqrt(q_enu.get([0, 0]) + q_enu.get([1, 1]));

  return {
    vdop,
    tdop,
    hdop,
    pdop,
    gdop,
  };
}

/**
 * fastCountVisibleSatellitesFromGeodetic
 * @category Utils
 * @param lat
 * @param lon
 * @param height
 * @param satellitePositionArrayECF
 * @param elevationMask
 * @returns {number} visSatellites
 */
export function fastCountVisibleSatellitesFromGeodetic(
  lat: number,
  lon: number,
  height: number,
  satellitePositionArrayECF: any,
  elevationMask: number,
  filterBelowHorizon = true,
) {
  const observerEcf = geodeticToEcf({
    longitude: degreesToRadians(lon),
    latitude: degreesToRadians(lat),
    height: height / 1000, // to km
  });
  return fastCountVisibleSatellitesFromEcf(
    observerEcf,
    satellitePositionArrayECF,
    elevationMask,
    filterBelowHorizon,
  );
}

/**
 * fastCountVisibleSatellitesFromEcf
 * @category Utils
 * @param observerEcf
 * @param satellitePositionArrayECF
 * @param elevationMask
 * @param filterBelowHorizon
 * @returns {number} visSatellites
 */
export function fastCountVisibleSatellitesFromEcf(
  observerEcf: any,
  satellitePositionArrayECF: any,
  elevationMask: number,
  filterBelowHorizon = true,
) {
  let nSatelliteVisible = 0;
  let elevationThreshold = 0;

  if (filterBelowHorizon) {
    elevationThreshold = Math.sin(degreesToRadians(elevationMask));
  }

  const observerEcfNormalized = {
    x: observerEcf.x,
    y: observerEcf.y,
    z: observerEcf.z,
  };

  vec.normalize(observerEcfNormalized);

  for (let iSatellite = 0; iSatellite < satellitePositionArrayECF.length; ++iSatellite) {
    const satPositionECF = satellitePositionArrayECF[iSatellite];
    const lineOfSight = vec.difference(satPositionECF, observerEcf);
    vec.normalize(lineOfSight);

    if (filterBelowHorizon) {
      // only use visible sattelites with an elevation  >= elevationMask
      if (vec.dot(lineOfSight, observerEcfNormalized) < elevationThreshold) {
        continue;
      }
    }

    nSatelliteVisible++;
  } // end for satellites

  return nSatelliteVisible;
}

/**
 * Calculate DOP for the timestamp, specified by setUserPosition
 * @category Utils
 * @param   {Array} satPosVisible
 * @returns {Float} hDop
 * @returns {Float} pDop
 * @returns {Float} vDop
 * @returns {Float} gDop
 */
export function getDop(satPosVisible) {
  // Pseudo-Range and Directional Derivative Loop
  const r: number[] = [];
  const Dx: number[] = [];
  const Dy: number[] = [];
  const Dz: number[] = [];
  const Dt: number[] = [];
  const numVisSat = satPosVisible.length;
  if (numVisSat < 4) {
    return dop(0, 0, 0, 0, 0);
  }
  for (let i = 0; i < numVisSat; i++) {
    const satpos = { ...satPosVisible[i] };
    // Calculate pseudo-ranges from target position to visible satellites
    const zUpRel = satpos.enu.zUp - satpos.obsGd.height;
    const sqrxEast = satpos.enu.xEast * satpos.enu.xEast;
    const sqryNorth = satpos.enu.yNorth * satpos.enu.yNorth;
    const sqrzUpRel = zUpRel * zUpRel;
    r.push(Math.sqrt(sqrxEast + sqryNorth + sqrzUpRel));
    // Calculate directional derivatives for East, North, Up and Time
    Dx.push(satpos.enu.xEast / r[i]);
    Dy.push(satpos.enu.yNorth / r[i]);
    Dz.push(zUpRel / r[i]);
    Dt.push(-1);
  }
  // Produce the Covariance Matrix from the Directional Derivatives
  const Alp: number[][] = [];
  for (let i = 0; i < numVisSat; i++) {
    Alp[i] = [];
    for (let j = 0; j < 4; j++) {
      Alp[i][j] = 0;
    }
  }
  for (let i = 0; i < numVisSat; i++) {
    Alp[i][0] = Dx[i];
    Alp[i][1] = Dy[i];
    Alp[i][2] = Dz[i];
    Alp[i][3] = Dt[i];
  }
  // Transpose Alp to get Brv
  const Brv: number[][] = [];
  for (let i = 0; i < 4; i++) {
    Brv[i] = [];
    for (let j = 0; j < numVisSat; j++) {
      Brv[i][j] = 0;
    }
  }
  for (let i = 0; i < 4; i++) {
    let j = 0;
    while (j < numVisSat) {
      Brv[i][j] = Alp[j][i];
      j++;
    }
  }
  // Matrix multiplication of Brv and Alp
  const Chl: number[][] = [];
  for (let i = 0; i < 4; i++) {
    Chl[i] = [];
    for (let j = 0; j < 4; j++) {
      Chl[i][j] = 0;
    }
  }
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 4; j++) {
      for (let k = 0; k < numVisSat; k++) {
        Chl[i][j] += Brv[i][k] * Alp[k][j];
      }
    }
  }
  // Inverse Chl
  const m = mathjs.matrix(mathjs.zeros(4, 4), 'dense');
  const mdata = (m as any)._data;
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 4; j++) {
      mdata[i][j] = Chl[i][j]; // Assign Chl to matrix m
    }
  }
  (m as any)._data = mdata;
  const minv = mathjs.inv(m);
  const minvdata = (minv as any)._data;
  const Dlt: number[][] = [];
  for (let i = 0; i < 4; i++) {
    Dlt[i] = [];
    for (let j = 0; j < numVisSat; j++) {
      Dlt[i][j] = minvdata[i][j];
    }
  }
  // Calculate DOP (only h, v, p, g have to be calculated)
  // var xdop = sqrt(Dlt[0][0]);
  // var ydop = sqrt(Dlt[1][1]);
  const vdop = Math.sqrt(Dlt[2][2]);
  const tdop = Math.sqrt(Dlt[3][3]);
  const hdop = Math.sqrt(Dlt[0][0] + Dlt[1][1]);
  const pdop = Math.sqrt(Dlt[0][0] + Dlt[1][1] + Dlt[2][2]);
  const gdop = Math.sqrt(Dlt[0][0] + Dlt[1][1] + Dlt[2][2] + Dlt[3][3]);
  return dop(vdop, tdop, hdop, pdop, gdop);
}
