import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getMetadataState } from '@app/store/data/globals';

import { getSocket } from '@app/services/Socket';
import { createSocketPromiseSync } from '@app/services/SocketPromiseWrapper';

interface MetadataEntry {
  lastUpdated: string;
  sourceUrl: string;
}

export interface MetadataState {
  stationInfo: MetadataEntry;
}

const initialState: MetadataState = {
  stationInfo: {
    lastUpdated: '',
    sourceUrl: 'https://cddis.nasa.gov/archive/gnss/products/2216/igs22p26_all.ssc',
  },
};

/**
 * Query station data source link from the meta data table
 */
export const queryMetadata = createAsyncThunk('data/metadata/get', async () => {
  const socket = getSocket();
  const eventName = 'getMetadata';
  const socketPromise = createSocketPromiseSync(socket);
  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  return (await socketPromise(eventName, {}, 10000)) as MetadataState;
});

const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setMetadata: (state: MetadataState, action: PayloadAction<MetadataState>) => action.payload,
  },
  extraReducers: {
    [queryMetadata.fulfilled.type]: (
      state: MetadataState,
      action: PayloadAction<MetadataState>,
    ) => {
      state.stationInfo = action.payload.stationInfo;
    },
  },
});

export const getStationInfo = (state: RootState) => getMetadataState(state).stationInfo;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setMetadata } = metadataSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default metadataSlice.reducer;
