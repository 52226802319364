import React from 'react';
import { NavLink } from 'react-router-dom';

import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps } from '@mui/system';

import { Route } from '@app/Routes';

const defaultStyle = ({
  theme,
  horizontal,
}: NavigationMenuProps & MUIStyledCommonProps<Theme> & { theme: Theme }) => ({
  display: horizontal ? 'inline-block' : 'block',
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  '& .item': {
    display: horizontal ? 'inline-block' : 'block',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  '& .link': {
    color: theme.palette.primary.main,
    display: 'block',
    padding: horizontal ? theme.spacing(0, 2) : theme.spacing(2),
    textDecoration: 'none',
    '&.active': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover:not(.active)': {
      color: 'black',
      backgroundColor: theme.palette.grey[200],
    },
  },
});

export type NavigationMenuProps = {
  routes: Route[];
  horizontal?: boolean;
  className?: string;
  onSelect?: () => void;
};

/**
 * NavigationMenu
 * @component
 * @category Components
 * @param {NavigationMenuProps} props contains page navigation routes
 */
const NavigationMenu = (props: NavigationMenuProps) => {
  const { routes, onSelect, className = 'navigation-menu' } = props;

  return (
    <ul className={className}>
      {routes.map((route) => (
        <li key={route.path} className="item">
          <NavLink to={route.link} className="link" onClick={onSelect}>
            {route.label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default styled(NavigationMenu)<NavigationMenuProps>(defaultStyle);
