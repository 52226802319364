/**
 * webGlEnabled Create canvas element. The canvas is not added to the
 * document itself, so it is never displayed in the
 * browser window.
 * @category Utils
 */
export const webGlEnabled = (() => {
  // Create canvas element. The canvas is not added to the
  // document itself, so it is never displayed in the
  // browser window.
  const canvas = document.createElement('canvas');
  // Get WebGLRenderingContext from canvas element.
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  // Report the result.
  if (gl && gl instanceof WebGLRenderingContext) {
    console.log('WebGL supported!');
    return true;
  }
  console.log('WebGL not supported!');
  return false;
})();

/* TODO This list was given by email and contains ids
   for every station we have data for
   -> should definetly be dynamically generated when
   the corresponding tables in postgres are in their final form
  */
/**
 * StationsWithData
 * @category Utils
 */
export const StationsWithData = [
  'BRUX',
  'REUN',
  'PARK',
  'THTG',
  'MAO0',
  'ABMF',
  'QAQ1',
  'SUTM',
  'SGOC',
  'WUH2',
  'GCGO',
  'LPGS',
  'ASCG',
  'STHL',
  'KIR8',
  'ENAO',
  'FALK',
  'RIO2',
  'M0SE',
  'MATG',
  'SCOR',
  'KRGG',
  'KIRU',
  'TRO1',
  'KOUR',
  'KOUG',
  'REDU',
  'CKIS',
  'YEL2',
  'VACS',
  'STJO',
  'OBE4',
  'KOUC',
  'CZTG',
  'NRMD',
  'NYAL',
];
