import { Position } from '@app/models';

import { degreesToRadians, fixed } from '@app/utils/Math';
import { Vector } from '@app/utils/Vector';

/**
 * ecefToEnu
 *
 * A function to convert  TLEs from the web server Earth-Centered Earth-Fixed (ECEF) coordinates (x, y, z) to
 * East-North-Up coordinates in a Local Tangent Plane that is centered at the
 * (WGS-84) Geodetic point (lat0, lon0, h0).
 * Adpated code from https://gist.github.com/govert/1b373696c9a27ff4c72a
 * @category Utils
 *
 * @param {Float} x (ecef) [m]
 * @param {Float} y (ecef) [m]
 * @param {Float} z (ecef) [m]
 * @param {Float} lat0 latitude  [deg]
 * @param {Float} lon0 longitude [deg]
 * @param {Float} h0   height    [m]
 * @returns {Float} xEast
 * @returns {Float} yNorth
 * @returns {Float} zUp
 */
export function ecefToEnu(x: any, y: any, z: any, lat0: any, lon0: any, h0: any) {
  // WGS-84 geodetic constants
  const a = 6378137.0; // WGS-84 Earth semimajor axis (m)
  const b = 6356752.314245; // Derived Earth semiminor axis (m)

  // const f = (a - b) / a;       // Ellipsoid Flatness
  const f = 0.003352810664775694;

  // const f_inv = 1.0 / f;    // Inverse flattening

  // const e_sq = f * (2 - f);    // Square of Eccentricity
  const e_sq = 0.0066943799901975545;

  // Convert to radians in notation consistent with the paper:
  const lambda = degreesToRadians(lat0);
  const phi = degreesToRadians(lon0);
  const s = Math.sin(lambda);
  const N = a / Math.sqrt(1 - e_sq * s * s);

  const sin_lambda = Math.sin(lambda);
  const cos_lambda = Math.cos(lambda);
  const cos_phi = Math.cos(phi);
  const sin_phi = Math.sin(phi);

  const x0 = (h0 + N) * cos_lambda * cos_phi;
  const y0 = (h0 + N) * cos_lambda * sin_phi;
  const z0 = (h0 + (1 - e_sq) * N) * sin_lambda;

  const xd = x - x0;
  const yd = y - y0;
  const zd = z - z0;

  // This is the matrix multiplication
  const xEast = -sin_phi * xd + cos_phi * yd;
  const yNorth = -cos_phi * sin_lambda * xd - sin_lambda * sin_phi * yd + cos_lambda * zd;
  const zUp = cos_lambda * cos_phi * xd + cos_lambda * sin_phi * yd + sin_lambda * zd;

  return {
    xEast,
    yNorth,
    zUp,
  };
}

export function distanceBetween(position1: Position, position2: Position): number {
  // Mean Earth Radius, as recommended for use by
  // the International Union of Geodesy and Geophysics,
  // see http://rosettacode.org/wiki/Haversine_formula
  const R = 6371000;

  const rad = Math.PI / 180;
  const lat1 = position1.latitude * rad;
  const lat2 = position2.latitude * rad;
  const sinDLat = Math.sin(((position2.latitude - position1.latitude) * rad) / 2);
  const sinDLon = Math.sin(((position2.longitude - position1.longitude) * rad) / 2);
  const a = sinDLat * sinDLat + Math.cos(lat1) * Math.cos(lat2) * sinDLon * sinDLon;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

export const convert2DMS = (decimalDegrees: number | undefined, precisionSeconds = 1) => {
  // Decimal Degrees to Degrees Minutes Seconds

  if (!decimalDegrees) {
    return 'undefined';
  }

  const degrees = Math.floor(decimalDegrees);
  let res = decimalDegrees - degrees;
  res *= 60;
  const minutes = Math.floor(res);
  res -= minutes;
  const seconds = res * 60;

  return `${degrees}° ${minutes}' ${fixed(seconds, precisionSeconds)}"`;
};

export const xyz2Position = (
  eci: Vector,
  outputInDeg = true,
  a = 6378137,
  e2 = 0.00669438003,
): Position => {
  // source:  https://geodezyx.github.io/GeodeZYX-Toolbox_v4/geodezyx.conv.html#geodezyx.conv.conv_coords.XYZ2GEO
  // originally based on: PYACS of J.-M. Nocquet

  const { x, y, z } = eci;
  const f = 1.0 - Math.sqrt(1 - e2);

  const tp = Math.sqrt(x ** 2 + y ** 2);
  const r = Math.sqrt(tp ** 2 + z ** 2);

  const tmu = Math.atan2((z / tp) * (1 - f + (e2 * a) / r), 1);

  let rLambda = Math.atan2(y, x);

  const s3 = Math.sin(tmu) ** 3;
  const c3 = Math.cos(tmu) ** 3;
  const t1 = z * (1 - f) + e2 * a * s3;
  const t2 = (1 - f) * (tp - e2 * a * c3);

  let rPhi = Math.atan2(t1, t2);

  let height = tp * Math.cos(rPhi) + z * Math.sin(rPhi);
  height -= a * Math.sqrt(1 - e2 * Math.sin(rPhi) ** 2);

  if (outputInDeg) {
    const rad2deg = 180 / 3.14159265358979323846; // make sure enough digits are used to get same result as in python
    rPhi *= rad2deg;
    rLambda *= rad2deg;
  }

  return { latitude: rPhi, longitude: rLambda, height };
};
