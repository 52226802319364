import { AppState } from './app';
import { DataState } from './data';
import { AccordionsState } from './ui/Accordions';
import { ChartsState } from './ui/Charts';
import { IndicatorState } from './ui/Indicator';
import { LayerControlState } from './ui/LayerControl';
import { SettingsState } from './ui/Settings';
import { TimeTravelState } from './ui/TimeTravel';

import { RootState } from '@app/store';

export const getAppState = (state: RootState): AppState => state.app;
export const getSettingsState = (state: RootState): SettingsState => state.settings;
export const getDataState = (state: RootState): DataState => state.data;
export const getAccordionsState = (state: RootState): AccordionsState => state.accordions;
export const getTimeTravelState = (state: RootState): TimeTravelState => state.timetravel;
export const getChartsState = (state: RootState): ChartsState => state.charts;
export const getLayerControlState = (state: RootState): LayerControlState => state.layercontrol;
export const getIndicatorState = (state: RootState): IndicatorState => state.indicator;
