import { updateTimeTravelDopTime } from './TimeTravel';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { degreesLat, degreesLong, eciToGeodetic } from 'satellite.js';

import { RootState } from '@app/store';
import { getSatLibrary } from '@app/store/data/SatLibrary';
import { getRealStations, getStations } from '@app/store/data/Stations';
import { selectAllTLEs, selectTLEsByTimestamp, SystemRecords } from '@app/store/data/Tle';
import { getSettingsState } from '@app/store/globals';

import { DOPType, Position, RealStation, Satellite, SatelliteSystem } from '@app/models';

import { StationsWithData } from '@app/utils/Constants';
import { startOfHour } from '@app/utils/Datetime';
import { getPositionAndVelocity, getVisibleSatellites } from '@app/utils/Dop';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface Coordinates {
  latitude?: number;
  longitude?: number;
  height?: number;
  elevation?: number;
}

export enum StationSelection {
  NONE = 'None',
  ALL = 'All IGS stations',
  DATA = 'Stations for performance analysis',
}

export interface SettingsState {
  showSatelliteCount: boolean;
  switchDOPType: boolean;
  visibleDOPType: DOPType;
  systemsEnabled: Array<SatelliteSystem>;
  visibleStationsSelected: StationSelection;
  selectedStation: string | null;
  selectedSatellite: string | null;
  selectedTime: number;
  selectedUtcTime: string;
  selectedCoords: Coordinates | null;
  currentView: [number, number, number];
  TECState: boolean;
  selectedElevationMask: number;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

export const dopTypesExplanation = {
  DOP: 'Dilution of precision is an indicator of satellite geometry for constellation of satellites used to determine a position. Lower the DOP value, better the position accuracy.',
  GDOP: 'Geometric Dilution Of Precision',
  PDOP: 'Position Dilution Of Precision',
  HDOP: 'Horizontal Dilution Of Precision',
  VDOP: 'Vertical Dilution Of Precision',
  TDOP: 'Time Dilution Of Precision',
};

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: SettingsState = {
  showSatelliteCount: false,
  switchDOPType: false,
  visibleDOPType: DOPType.GDOP,
  systemsEnabled: [SatelliteSystem.GALILEO],
  visibleStationsSelected: StationSelection.DATA,
  selectedStation: null,
  selectedSatellite: null,
  selectedTime: startOfHour().valueOf(),
  selectedUtcTime: startOfHour().toISOString(),
  selectedCoords: null,
  currentView: [40, 0, 2],
  TECState: false,
  selectedElevationMask: 5,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setShowSatelliteCount: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.showSatelliteCount = action.payload;
      // turn DOP and tec layer off when satellite count layer is activated
      if (action.payload) {
        state.switchDOPType = false;
        state.TECState = false;
      }
    },
    setSwitchDOPType: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.switchDOPType = action.payload;
      // turn satellite count and tec layer off when DOP is activated
      if (action.payload) {
        state.showSatelliteCount = false;
        state.TECState = false;
      }
    },
    setVisibleDOPType: (state: SettingsState, action: PayloadAction<DOPType>) => {
      state.visibleDOPType = action.payload;
    },
    setTECState: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.TECState = action.payload;
      // turn dop and satellite count layer off when tec is activated
      if (action.payload) {
        state.switchDOPType = false;
        state.showSatelliteCount = false;
      }
    },
    toggleSystem: (state: SettingsState, action: PayloadAction<SatelliteSystem>) => {
      if (state.systemsEnabled.includes(action.payload)) {
        state.systemsEnabled = state.systemsEnabled.filter((s) => s !== action.payload);
      } else {
        state.systemsEnabled = [action.payload, ...state.systemsEnabled];
      }
    },
    setSystemsEnabled: (state: SettingsState, action: PayloadAction<SatelliteSystem[]>) => {
      state.systemsEnabled = action.payload;
    },
    setVisibleStationsSelected: (state: SettingsState, action: PayloadAction<StationSelection>) => {
      // this reducer also resets the currently selected station to null
      state.visibleStationsSelected = action.payload;

      // Reset station selection when switching between visible stations.
      // Do not reset if we switch to selection 'All'
      if (action.payload !== StationSelection.ALL) {
        state.selectedStation = null;
      }
    },
    setSelectedStation: (state: SettingsState, action: PayloadAction<string | null>) => {
      state.selectedStation = action.payload;
      // reset selected coords
      state.selectedCoords = null;
    },
    setSelectedSatellite: (state: SettingsState, action: PayloadAction<string | null>) => {
      state.selectedSatellite = action.payload;
      // reset selected coords
      state.selectedCoords = null;
    },
    setSelectedTime: (state: SettingsState, action: PayloadAction<number>) => {
      state.selectedTime = action.payload;
      state.selectedUtcTime = new Date(action.payload).toISOString(); // assumes that passes timestamp is utc time
    },
    setSelectedUtcTime: (state: SettingsState, action: PayloadAction<string>) => {
      state.selectedUtcTime = action.payload;
      state.selectedTime = new Date(action.payload).getTime();
    },
    setCurrentView: (state: SettingsState, action: PayloadAction<[number, number, number]>) => {
      state.currentView = action.payload;
    },
    updateObs: (state: SettingsState, action: PayloadAction<Coordinates | null>) => {
      if (action.payload === null) {
        state.selectedCoords = null;
      } else {
        state.selectedCoords = {
          // set default values
          latitude: 0,
          longitude: 0,
          height: 0,
          elevation: 0,
          // override with previous values
          ...state.selectedCoords,
          // override with new values
          ...action.payload,
        };
      }
    },
    setSelectedElevationMask: (state: SettingsState, action: PayloadAction<number>) => {
      state.selectedElevationMask = action.payload;
    },
  },
  extraReducers: {
    [updateTimeTravelDopTime.type]: (state) => ({
      ...state,
      selectedTime: Date.now(),
    }),
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */

export const getVisibleDopType = (state: RootState) => getSettingsState(state).visibleDOPType;
export const getSystemsEnabled = (state: RootState) => getSettingsState(state).systemsEnabled;
export const getVisibleStationsSelected = (state: RootState) =>
  getSettingsState(state).visibleStationsSelected;
export const getSelectedTime = (state: RootState) => getSettingsState(state).selectedTime;
export const getSelectedUtcTime = (state: RootState) => getSettingsState(state).selectedUtcTime;
export const getSelectedSatelliteId = (state: RootState) =>
  getSettingsState(state).selectedSatellite;
export const getSwitchDOPType = (state: RootState) => getSettingsState(state).switchDOPType;
export const getCurrentView = (state: RootState) => getSettingsState(state).currentView;
export const getVisibleTECState = (state: RootState) => getSettingsState(state).TECState;
export const getShowSatelliteCount = (state: RootState) =>
  getSettingsState(state).showSatelliteCount;
export const getSelectedElevationMask = (state: RootState) =>
  getSettingsState(state).selectedElevationMask;

/**
 * This selector returns the currently selected station id or null if none is selected.
 *
 * @param {Object} state Current redux store state
 * @return {(Object|null)} Currently selected station
 */
export const getSelectedStationId = (state: RootState) => getSettingsState(state).selectedStation;

export const selectTLEs = createSelector([selectAllTLEs, getSelectedTime], (state, timestamp) =>
  selectTLEsByTimestamp(state, timestamp),
);

export const getSelectedSatellites = createSelector(
  [selectTLEs, getSystemsEnabled, getSatLibrary],
  (satellites, systems, library) => {
    // select permanently unhealty satellites
    const unhealty = library.filter((s) => s.longTermUnhealthy).map((s) => s.prn);

    return Object.entries(satellites)
      .map((s: [string, Satellite[]]) => (systems.includes(s[0] as SatelliteSystem) ? s[1] : []))
      .flat()
      .filter((s) => s !== null && s !== undefined && !unhealty.includes(s.prn));
  },
);

export const getSelectedTLEs = createSelector(
  [selectAllTLEs, getSystemsEnabled],
  (satellites, systems): Record<string, SystemRecords> => {
    const tles: Record<string, SystemRecords> = {};
    systems.forEach((system) => {
      tles[system as string] = satellites[system];
    });
    return tles;
  },
);

/**
 * This selector returns the currently selected station or null if none is selected.
 *
 */
export const getSelectedStation = createSelector(
  [getStations, getSelectedStationId],
  (stations, stationId) => {
    if (!(stations && stationId)) {
      return null;
    }

    const station = stations.find((station) => station.id === stationId);
    if (!station) {
      return null;
    }

    return station;
  },
);

/**
 * This selector returns an array of satellites, visible by the currently selected station or null if none is selected.
 *
 */
export const getSatellitesOfStation = createSelector(
  [getSelectedStation, getSelectedTime, getSelectedSatellites, getSelectedElevationMask],
  (station, selectedTime, satellites, elevationMask) => {
    if (station !== null) {
      const date = new Date(selectedTime);
      const satposeci = getPositionAndVelocity(satellites, date);
      const visibleSatellites = getVisibleSatellites(
        satellites,
        satposeci,
        station.position.longitude,
        station.position.latitude,
        station.position.height === undefined ? 0 : station.position.height,
        elevationMask,
      );
      return visibleSatellites;
    }

    return null;
  },
);

export const getSelectedSatellite = createSelector(
  [getSatellitesOfStation, getSelectedSatelliteId],
  (satellites, satelliteId) => {
    if (satellites != null) {
      if (satellites.length > 0) {
        for (let i = 0; i < satellites.length; i++) {
          if (satellites[i].id === satelliteId) {
            return satellites[i];
          }
        }
      }
    }
    return null;
  },
);

export const getSelectedSatellitePosition = createSelector(
  [getSelectedSatellite, getSelectedTime],
  (satellite, time) => {
    if (satellite === null) {
      return null;
    }

    const satposeci = getPositionAndVelocity([satellite], new Date(time))[0];
    const position = eciToGeodetic(satposeci.eci, satposeci.gmst);

    position.latitude = degreesLat(position.latitude);
    position.longitude = degreesLong(position.longitude);

    return position as Position;
  },
);

export const getSelectedCoords = createSelector(
  [getSettingsState, getSelectedStation],
  (state, station) => {
    if (state.selectedCoords !== null) {
      return state.selectedCoords;
    }

    if (station !== null) {
      return station.position;
    }

    return null;
  },
);

export const filterStationsWithData = (state: RealStation[]) =>
  state.filter((station) => StationsWithData.indexOf(station.id) !== -1);

export const getStationsWithData = createSelector([getRealStations], (state) =>
  filterStationsWithData(state),
);

export const getVisibleStations = createSelector(
  [getRealStations, getVisibleStationsSelected],
  (state, selection) => {
    let stations: RealStation[] = [];

    switch (selection) {
      case StationSelection.ALL:
        stations = [...state];
        break;
      case StationSelection.DATA:
        stations = filterStationsWithData(state);
        break;
      case StationSelection.NONE:
        stations = [];
        break;
      default:
        console.log('No option in transformStationData called ', selection);
        break;
    }

    return stations;
  },
);

/* ********************* *
 *    Export actions     *
 * ********************* */

export const {
  setShowSatelliteCount,
  setSwitchDOPType,
  setVisibleDOPType,
  toggleSystem,
  setSystemsEnabled,
  setVisibleStationsSelected,
  setSelectedStation,
  setSelectedTime,
  setSelectedUtcTime,
  setSelectedSatellite,
  setCurrentView,
  updateObs,
  setTECState,
  setSelectedElevationMask,
} = settingsSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default settingsSlice.reducer;
