import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton } from '@mui/material';

import NavigationMenu from '@app/components/NavigationMenu';

import { SideMenuRoutes } from '@app/Routes';

import appStyles from '@app/App.style';

/**
 * Sidemenu holds the hamburger menu
 * @component
 * @category Components
 */
const SideMenu = () => {
  const [drawer, setDrawer] = useState(false);
  return (
    <>
      <IconButton onClick={() => setDrawer(true)} size="large">
        <MenuIcon style={{ color: 'black' }} fontSize="large" />
      </IconButton>
      <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)} sx={appStyles.menu}>
        <Box sx={appStyles.menu}>
          <NavigationMenu routes={SideMenuRoutes} onSelect={() => setDrawer(false)} />
        </Box>
      </Drawer>
    </>
  );
};

export default SideMenu;
