import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getSatelliteClockAccuracyState } from '@app/store/data/globals';

import { getSocket } from '@app/services/Socket';
import { createSocketPromiseSync } from '@app/services/SocketPromiseWrapper';

import { SatelliteSystem } from '@app/models';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface SatelliteClockDeviation {
  [prn: string]: number[];
}

export interface SatelliteClockRMS {
  [prn: string]: number;
}

export interface SatelliteClockAccuracyDataset {
  epochs: string[] | null;
  clock_dev: SatelliteClockDeviation | null;
  clock_dev_rms: SatelliteClockRMS | null;
  tau: number[] | null;
  oadev: SatelliteClockDeviation | null;
}

export interface SatelliteClockAccuracyState {
  isLoading: boolean;
  hasError: boolean;
  station: string | null;
  data: {
    [SatelliteSystem.GALILEO]: SatelliteClockAccuracyDataset | null;
    [SatelliteSystem.GPS]: SatelliteClockAccuracyDataset | null;
    [SatelliteSystem.GLONASS]: SatelliteClockAccuracyDataset | null;
    [SatelliteSystem.BEIDOU]: SatelliteClockAccuracyDataset | null;
  };
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

/**
 * This function calls our api and is used by a async thunk
 *
 * @param {object} queryDict (contains query parameters for the database access)
 * @param {function} getState (redux function returning whole state tree. It actually does not need to be passed when dispatching, no idea why... :( )
 * @param {string} requestID (id of this thunk call. Apparently this is also passed automatically when dispatching, again no idea why...)
 *
 * @returns {Promise} data
 */
async function getSatelliteClockAccuracyData(queryDict: { [index: string]: any }) {
  const socket = getSocket();
  const eventName = 'getSatelliteClockAccuracyData';

  const socketPromise = createSocketPromiseSync(socket);
  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  const data = await socketPromise(eventName, queryDict, 10000); // TODO useful timeout

  return data;
}

/**
 * Here we define our asyncthunk passing it a action type and a async function returning a promise
 *
 */
export const querySatelliteClockAccuracyData = createAsyncThunk(
  'data/satelliteClockAccuracyData/get',
  getSatelliteClockAccuracyData,
);

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: SatelliteClockAccuracyState = {
  isLoading: false,
  hasError: false,
  station: null,
  data: {
    [SatelliteSystem.GALILEO]: null,
    [SatelliteSystem.GPS]: null,
    [SatelliteSystem.GLONASS]: null,
    [SatelliteSystem.BEIDOU]: null,
  },
};

const satelliteClockAccuracySlice = createSlice({
  name: 'satelliteClockAccuracyData',
  initialState,
  reducers: {
    setSatelliteClockAccuracyMap: (
      state: SatelliteClockAccuracyState,
      action: { [index: string]: any },
    ) => {
      const newState = { ...state };
      newState.data = action.payload;
      return newState;
    },
  },
  extraReducers: {
    [querySatelliteClockAccuracyData.pending.type]: (state: SatelliteClockAccuracyState) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [querySatelliteClockAccuracyData.fulfilled.type]: (
      state: SatelliteClockAccuracyState,
      action: { [index: string]: any },
    ) => {
      state.station = action.payload.station || null;
      state.data[SatelliteSystem.GALILEO] = action.payload.GALILEO || null;
      state.data[SatelliteSystem.GPS] = action.payload.GPS || null;
      state.data[SatelliteSystem.BEIDOU] = action.payload.BEIDOU || null;
      state.data[SatelliteSystem.GLONASS] = action.payload.GLONASS || null;
      state.isLoading = false;
      state.hasError = false;
    },
    [querySatelliteClockAccuracyData.rejected.type]: (state: SatelliteClockAccuracyState) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const selectSatelliteClockAccuracy = (state: RootState) =>
  getSatelliteClockAccuracyState(state).data;

export const isLoadingSatelliteClockAccuracy = (state: RootState) =>
  getSatelliteClockAccuracyState(state).isLoading;

export const selectSatelliteClockAccuracyStation = (state: RootState) =>
  getSatelliteClockAccuracyState(state).station;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setSatelliteClockAccuracyMap } = satelliteClockAccuracySlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default satelliteClockAccuracySlice.reducer;
