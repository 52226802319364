import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getChartsState } from '@app/store/globals';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface ChartsState {
  visible: string[]; // list of chart components names, which are visible
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState = {
  visible: [
    'DopPlot',
    'SkyPlot',
    'VisibleSatPlot',
    'AzimuthElevationPlotAllSatellites',
    'AnglePlot',
    'ElevationPlot',
    'AzimuthPlot',
    'AzimuthElevationPlot',
  ],
};

export const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    showChart: (state: ChartsState, action: PayloadAction<string>) => {
      state.visible.push(action.payload);
    },
    hideChart: (state: ChartsState, action: PayloadAction<string>) => {
      state.visible = state.visible.filter((c) => c !== action.payload);
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const getVisibleCharts = (state: RootState) => getChartsState(state).visible;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { showChart, hideChart } = chartsSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default chartsSlice.reducer;
