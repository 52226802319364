import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { queryMetadata } from '@app/store/data/Metadata';
import {
  querySatelliteStatusChanges,
  querySatLibrary,
  querySatStatus,
} from '@app/store/data/SatLibrary';
import { queryStations } from '@app/store/data/Stations';
import { queryTLE } from '@app/store/data/Tle';
import { getAppState } from '@app/store/globals';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface AppState {
  initializing: boolean;
  fulfilled: string[];
}

const actionTypes = [
  queryStations.fulfilled.type,
  queryTLE.fulfilled.type,
  querySatStatus.fulfilled.type,
  querySatLibrary.fulfilled.type,
];

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: AppState = {
  initializing: false,
  fulfilled: [],
};

const appSlice = createSlice({
  name: '@app/App',
  initialState,
  reducers: {
    startInitialization: (state: AppState, action: PayloadAction<void>) => {
      state.initializing = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => actionTypes.includes(action.type),
      (state: AppState, action: PayloadAction<unknown>) => {
        if (!state.fulfilled.includes(action.type)) {
          state.fulfilled.push(action.type);
        }
      },
    );
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const isAppInitialized = (state: RootState) =>
  getAppState(state).fulfilled.length === actionTypes.length;

/* ********************* *
 *    Export actions     *
 * ********************* */
/* We don't have any actions to dispatch. */

export const initializeApp = createAsyncThunk('app/initialize', (args, thunkApi) => {
  if (!getAppState(thunkApi.getState() as RootState).initializing) {
    thunkApi.dispatch(appSlice.actions.startInitialization);
    thunkApi.dispatch(queryStations());
    thunkApi.dispatch(queryTLE());
    thunkApi.dispatch(querySatLibrary());
    thunkApi.dispatch(querySatStatus());
    thunkApi.dispatch(querySatelliteStatusChanges());
    thunkApi.dispatch(queryMetadata());
  }
});

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default appSlice.reducer;
