import {
  SatelliteLibraryItem,
  SatelliteStatus,
  SatelliteStatusChanges,
  SatelliteStatusItem,
  StatusChangesPerEpoch,
} from '@app/models';

import { differenceIn, startOfDay, utc } from '@app/utils/Datetime';

export function getSatelliteStatus(input: string): SatelliteStatus {
  // set default status as unhealty
  let status = SatelliteStatus.UNHEALTHY;

  switch (input) {
    case '0':
    case 'operational':
      status = SatelliteStatus.HEALTHY;
      break;
    case '1':
    case 'none':
    case 'not_usable':
    case 'not usable':
      /* Note: i am not happy with this duplication, but did not find any other simple way for the moment!
         This needs to be revisited with the new api anyway */
      status = SatelliteStatus.UNHEALTHY;
      break;
    case '2':
      status = SatelliteStatus.MARGINAL;
      break;
    case 'partly_not_usable':
    case 'partly not usable':
      /* Note: i am not happy with this duplication, but did not find any other simple way for the moment!
         This needs to be revisited with the new api anyway */
      status = SatelliteStatus.PARTIAL_UNHEALTHY;
      break;
    case 'testing':
      status = SatelliteStatus.TESTING;
      break;
    case 'retired':
      status = SatelliteStatus.RETIRED;
      break;
    case 'experiment':
      status = SatelliteStatus.EXPERIMENT;
      break;
    default:
      break;
  }

  return status;
}

/**
 * Gets the satellite health status from the statusArray. This must be sorted by date for this function to work!
 *
 * @param statusArray array containing an SatelliteStatusItem for every epoch sorted by date
 * @param prn satellite prn
 * @param date date for which the status is required
 * @returns status at the given date, if not found returns 'None'
 */
export function getSatStatusFromPRN(
  statusArray: SatelliteStatusItem[],
  prn: string,
  date: Date,
): SatelliteStatus {
  for (let i = 0; i < statusArray.length; i++) {
    const statusDate = new Date(statusArray[i].date);
    if (statusDate >= date || i === statusArray.length - 1) {
      if (Object.prototype.hasOwnProperty.call(statusArray[i].status, prn)) {
        return statusArray[i].status[prn];
      }
    }
  }

  return SatelliteStatus.NONE;
}

/**
 * gets the satellite status from two sources: the satStatus data array.
 * If this returns the status 'None' the satellite status is taken from the satellite library item.
 * Item can be passed, if not passed, the library is searched for the correct entry based on the satellite prn.
 *
 * @param satellitePrn satellite prn
 * @param selectedTime selected time
 * @param satStatus satellite status per epochs
 * @param satLib satellite library
 * @param satStatusChanges satellite status changes
 * @param satLibItem satellite library entry for current satellite
 *
 * @deprecated
 *
 * @returns satellite status, ether HEALTHY or UNHEALTHY
 */
export const checkSatelliteStatus = (
  satellitePrn: string,
  selectedTime: string,
  satStatus: SatelliteStatusItem[],
  satLib: SatelliteLibraryItem[],
  satStatusChanges: SatelliteStatusChanges,
  satLibItem?: SatelliteLibraryItem,
) => {
  const prnStatus = getSatStatusFromPRN(
    satStatus,
    satellitePrn,
    startOfDay(utc(selectedTime)).toDate(),
  );

  // satellite is partially unhealthy, check the satellite status changes to see current status
  if (prnStatus === SatelliteStatus.MARGINAL) {
    const changesPerEpoch = satStatusChanges[satellitePrn];
    return checkForSatelliteStatusChange(changesPerEpoch, selectedTime);
  }

  // prnStatus === 'None', use the status from the satellite library
  if (!satLibItem) {
    // we first have to search for the library entry
    satLibItem = satLib.find((lib) => lib.prn === satellitePrn); // this should be rethought to avoid search
  }

  const statusFromSatLib = satLibItem ? satLibItem.status : SatelliteStatus.UNHEALTHY;

  if (prnStatus === SatelliteStatus.NONE && statusFromSatLib === SatelliteStatus.HEALTHY) {
    return SatelliteStatus.UNHEALTHY;
  }

  return prnStatus;
};

/**
 * The satellites that with the status partially unhealthy toggle between healthy and unhealthy.
 * The exact satellite status for these is contained in the satellite status changes.
 * This method searches the change table for a specific satellite for the status at the selected time.
 *
 * @param changesPerEpoch changes for a given satellite
 * @param selectedTime selected time
 * @returns satellite status, ether healthy or unhealthy
 */
export const checkForSatelliteStatusChange = (
  changesPerEpoch: StatusChangesPerEpoch,
  selectedTime: string,
): SatelliteStatus => {
  let currentSatelliteStatus = SatelliteStatus.UNHEALTHY;
  const changeEpochs = Object.keys(changesPerEpoch);
  const time = utc(selectedTime);

  const epochs = changeEpochs
    .map((e) => ({ epoch: e, diff: differenceIn(utc(e), time, 'minute') })) // calculate time difference
    .filter((e) => e.diff <= 0) // filter out entries in the future
    .sort((e1, e2) => (Math.abs(e1.diff) <= Math.abs(e2.diff) ? -1 : 1)); // sort by shortest abs time difference

  if (epochs.length > 0) {
    currentSatelliteStatus = getSatelliteStatus(changesPerEpoch[epochs[0].epoch]);
  }

  return currentSatelliteStatus;
};
