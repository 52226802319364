import React, { useEffect, useRef, useState } from 'react';
import { Navigate, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import { Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import Loading from '@app/components/Loading';
import NavigationMenu, { NavigationMenuProps } from '@app/components/NavigationMenu';
import SideMenu from '@app/components/SideMenu';

import NotFound from '@app/pages/NotFound';

import { initializeApp, isAppInitialized } from '@app/store/app';

import { initSocket } from '@app/services/Socket';

import { useDispatch } from '@app/hooks/useDispatch';
import { useSelector } from '@app/hooks/useSelector';

import { FooterRoutes, SideMenuRoutes } from '@app/Routes';

import DLRSignet from '@app/assets/images/dlr-signet-black.png';
import GKSignature from '@app/assets/images/gk-signature-green.png';
import { ReactComponent as SigPerMonLogo } from '@app/assets/images/logo-sigpermon-simple.svg';
import testinPhase from '@app/assets/images/Testphase_message.svg';

import '@app/App.scss';
import styles from '@app/App.style';

// init socket.io
initSocket();

const FooterMenu = styled(NavigationMenu)<NavigationMenuProps>(({ theme }) => ({
  '& .link': {
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    color: 'black',
    backgroundColor: 'transparent',
    '&.active': {
      color: 'black',
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));

const Title = styled(Typography)`
  display: inline;
  vertical-align: bottom;
  font-size: 2rem;
`;

const Header = () => (
  <Container>
    <Grid container direction="row" alignItems="center" style={{ flexWrap: 'nowrap' }}>
      <Grid item>
        <NavLink to="/welcome" style={{ textDecoration: 'none', color: 'black' }} title="Home">
          <SigPerMonLogo className="sigpermon-logo" viewBox="20 0 90 70" />
        </NavLink>
      </Grid>
      <Grid item style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden' }}>
        <Title variant="h4" sx={{ marginLeft: 1 }}>
          GNSS Performance Monitoring
        </Title>
      </Grid>
      <Grid item style={{ paddingRight: '16px' }}>
        <a
          href="https://www.dlr.de/gk/en"
          target="_blank"
          aria-label="Galileo Competence Center Website"
          rel="noreferrer"
          title="Galileo Competence Center Website"
        >
          <img className="gk-signature" src={GKSignature} alt="Galileo Competence Center Logo" />
        </a>
      </Grid>
      <Grid item>
        <a
          href="https://www.dlr.de/en"
          target="_blank"
          aria-label="German Aerospace Center Website"
          rel="noreferrer"
          title="German Aerospace Center Website"
        >
          <img className="dlr-logo" src={DLRSignet} alt="German Aerospace Center Logo" />
        </a>
      </Grid>
      <Grid item>
        <SideMenu />
      </Grid>
    </Grid>
  </Container>
);

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isInitialized = useSelector(isAppInitialized);

  const mainRef = useRef<HTMLDivElement | null>(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    // load some data at app start
    dispatch(initializeApp());

    // define and register global error listener
    const handleGlobalError = (event: ErrorEvent) => {
      const { colno, error, lineno, message } = event;
      // Note that col & error are new to the HTML 5 spec and may not be
      // supported in every browser.  It worked for me in Chrome.
      let extra = !colno ? '' : `\ncolumn: ${colno}`;
      extra += !error ? '' : `\nerror: ${error}`;

      // You can view the information in an alert to see things working like this:
      console.error(`Error: ${message}\nurl: ${message}\nline: ${lineno}${extra}`);
      console.log(event);

      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });

      // If you return true, then error alerts (like in older versions of
      // Internet Explorer) will be suppressed.
      const suppressErrorAlert = true;
      return suppressErrorAlert;
    };

    const handleGlobalUnhandledRejection = (e: PromiseRejectionEvent) => {
      throw new Error(e.reason.stack);
    };

    window.addEventListener('error', handleGlobalError);
    window.addEventListener('unhandledrejection', handleGlobalUnhandledRejection);
    return () => {
      window.removeEventListener('error', handleGlobalError);
      window.removeEventListener('unhandledrejection', handleGlobalUnhandledRejection);
    };
  }, []);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTo({ top: 0, left: 0 });
    }

    // navigating view to subentries of glossary
    if (location.hash !== '') {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  // decide which view to show
  return (
    <>
      <Grid container spacing={0} direction="column" sx={styles.root}>
        <Grid container spacing={0} direction="row" sx={styles.header}>
          <Header />
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          className="content-area"
          sx={styles.main}
          ref={mainRef}
        >
          <Grid container spacing={0} direction="row" sx={styles.content}>
            <Routes>
              {[...SideMenuRoutes, ...FooterRoutes].map((route) =>
                route.element ? (
                  <Route key={route.path} path={route.path} element={route.element} />
                ) : null,
              )}
              <Route path="/" element={<Navigate replace to="/welcome" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Grid>
          <Grid container spacing={0} direction="row" sx={styles.footer}>
            <FooterMenu routes={FooterRoutes} horizontal />
          </Grid>
        </Grid>
      </Grid>
      <Loading isLoading={!isInitialized} variant="tiles" overlay fixed />
    </>
  );
}

export default App;
