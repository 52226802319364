import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getTimeTravelState } from '@app/store/globals';

import {
  DateTimeInterval,
  now,
  startOfDay,
  startOfHour,
  stringToTimeExpression,
  TimeExpression,
} from '@app/utils/Datetime';

/* *********************** *
 *    Type definitions     *
 * *********************** */

export interface TimeTravelState {
  timeTravelMode: string;
  autoModeEnabled: boolean;
  selectedSatTime: number;
  selectedTime: string;
  interval: DateTimeInterval<string>;
  step: TimeExpression;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */
/**
 * generates time series
 *
 * @param {int} start start time in ms
 * @param {int} stop stop time in ms
 *
 * @returns {array} time series
 */
export function createTimeSeries(start: number, stop: number, step: number): number[] {
  const range: number = stop - start;
  const ticks: number[] = [];
  for (let i = 0; i <= range; i += step) {
    ticks.push(i + start);
  }
  return ticks;
}

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState = {
  timeTravelMode: 'Week',
  autoModeEnabled: false,
  selectedSatTime: startOfHour().valueOf(),
  selectedTime: now().toISOString(),
  interval: {
    start: startOfDay().subtract(14, 'day').toISOString(),
    end: startOfDay().add(7, 'day').toISOString(),
  },
  step: stringToTimeExpression('15m'),
};

export const timeTravelSlice = createSlice({
  name: 'timetravel',
  initialState,
  reducers: {
    setTimeTravelMode: (state: TimeTravelState, action: PayloadAction<string>) => {
      state.timeTravelMode = action.payload;
    },
    setTimeTravelStep: (state: TimeTravelState, action: PayloadAction<string | TimeExpression>) => {
      const step =
        typeof action.payload === 'string'
          ? stringToTimeExpression(action.payload)
          : action.payload;
      state.step = step;
    },
    setAutoModeEnabled: (state: TimeTravelState, action: PayloadAction<boolean>) => {
      state.autoModeEnabled = action.payload;
    },
    updateTimeTravelDopTime: (state: TimeTravelState) => {
      if (state.autoModeEnabled) {
        // state.selectedTime = Date.now();
      }
    },
    updateTimeTravelSatTime: (state: TimeTravelState) => {
      if (state.autoModeEnabled) {
        state.selectedSatTime = Date.now();
      }
    },
    updateTimeTravelInterval: (
      state: TimeTravelState,
      action: PayloadAction<DateTimeInterval<string>>,
    ) => {
      state.interval = action.payload;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
const getTimeTravelMode = (state: RootState): string => getTimeTravelState(state).timeTravelMode;

export const getSelectedTimeTravelAutoModeEnabled = (state: RootState): boolean =>
  getTimeTravelState(state).autoModeEnabled;
export const getSelectedTimeTravelSatTime = (state: RootState): number =>
  getTimeTravelState(state).selectedSatTime;
export const getSelectedTimeTravelInterval = (state: RootState) =>
  getTimeTravelState(state).interval;

export const getSelectedTimeTravelMode = createSelector(
  [getSelectedTimeTravelAutoModeEnabled, getTimeTravelMode],
  (isLive, mode) => (isLive ? 'Hour' : mode),
);

export const getSelectedTimeTravelStep = (state: RootState): TimeExpression =>
  getTimeTravelState(state).step;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const {
  setTimeTravelMode,
  setAutoModeEnabled,
  setTimeTravelStep,
  updateTimeTravelDopTime,
  updateTimeTravelSatTime,
  updateTimeTravelInterval,
} = timeTravelSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default timeTravelSlice.reducer;
