import React, { ReactNode } from 'react';

import loadable from '@loadable/component';

import CircularLoading from '@app/components/CircularLoading';

import StandardPage from '@app/pages/StandardPage';

const Page = loadable((props) => import(`@app/pages/${props.page}`), {
  cacheKey: (props) => props.page,
  fallback: (
    <StandardPage>
      <CircularLoading isLoading />
    </StandardPage>
  ),
});

export interface RouteConfig {
  label: string;
  path: string;
  link?: string;
  element?: ReactNode;
}

export class Route {
  readonly label: string;

  readonly path: string;

  readonly link: string;

  readonly element: ReactNode | undefined;

  constructor({ label, path, link, element }: RouteConfig) {
    this.label = label;
    this.path = path;
    this.link = link || path;
    this.element = element;
  }
}

export const SideMenuRoutes: Route[] = [
  {
    label: 'Welcome',
    path: '/welcome',
    link: '/welcome',
    element: <Page page="Welcome" />,
  },
  {
    label: 'Local Performance Analysis',
    path: '/local-performance-analysis',
    element: <Page page="SystemPerformanceComparison" />,
  },
  {
    label: 'GNSS Basics',
    path: '/gnss-basics',
    element: <Page page="GNSSBasics" />,
  },
  {
    label: 'GNSS Advanced',
    path: '/gnss-advanced/*',
    link: '/gnss-advanced',
    element: <Page page="GNSSAdvanced" />,
  },
  {
    label: 'Ionospheric conditions',
    path: '/ionospheric-conditions',
    element: <Page page="Ionosphere" />,
  },
  {
    label: 'Satellite Clock',
    path: '/satellite-clock',
    element: <Page page="SatelliteClockAccuracy" />,
  },
  {
    label: 'Satellite Library',
    path: '/satellite-library',
    element: <Page page="SatelliteLibrary" />,
  },
  {
    label: 'About',
    path: '/about',
    element: <Page page="About" />,
  },
  {
    label: 'Glossary',
    path: '/glossary',
    element: <Page page="Glossary" />,
  },
  {
    label: 'Disclaimer',
    path: '/disclaimer',
    element: <Page page="Disclaimer" />,
  },
].map((route) => new Route(route));

export const FooterRoutes: Route[] = [
  {
    label: 'Imprint and terms of use',
    path: '/imprint-and-terms',
    element: <Page page="Imprint" />,
  },
  {
    label: 'Accessibility',
    path: '/accessibility',
    element: <Page page="Accessibility" />,
  },
  {
    label: 'Attributions',
    path: '/attributions',
    element: <Page page="Attributions" />,
  },
  {
    label: 'Privacy',
    path: '/privacy-policy',
    element: <Page page="PrivacyAgreement" />,
  },
  {
    label: 'Contact',
    path: '/contact',
    element: <Page page="Contact" />,
  },
  {
    label: 'Disclaimer',
    path: '/disclaimer',
    element: <Page page="Disclaimer" />,
  },
].map((route) => new Route(route));

export const GNSSAdvancedRoutes: Route[] = [
  {
    label: 'Map',
    path: 'map',
    element: <Page page="GNSSAdvanced/Map" />,
  },
  {
    label: 'Charts',
    path: 'charts',
    element: <Page page="GNSSAdvanced/Charts" />,
  },
].map((route) => new Route(route));
