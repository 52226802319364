/**
 * Some helper functions regarding vector arithmethic
 */
export interface Vector {
  x: number;
  y: number;
  z: number;
}

export function magnitude(vector: Vector): number {
  return Math.sqrt(magnitudeSquared(vector));
}

export function magnitudeSquared(vector: Vector): number {
  return vector.x * vector.x + vector.y * vector.y + vector.z * vector.z;
}

export function multiply(vector: Vector, factor: number): Vector {
  return { x: vector.x * factor, y: vector.y * factor, z: vector.z * factor };
}

export function divide(vector: Vector, factor: number): Vector {
  return { x: vector.x / factor, y: vector.y / factor, z: vector.z / factor };
}

export function difference(a: Vector, b: Vector): Vector {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
    z: a.z - b.z,
  };
}

export function addition(a: Vector, b: Vector): Vector {
  return {
    x: a.x + b.x,
    y: a.y + b.y,
    z: a.z + b.z,
  };
}

export function dot(a: Vector, b: Vector): number {
  return a.x * b.x + a.y * b.y + a.z * b.z;
}

export function normalize(vector: Vector): Vector {
  const mag = magnitude(vector);
  const normalized = {
    x: (vector.x /= mag),
    y: (vector.y /= mag),
    z: (vector.z /= mag),
  };

  return normalized;
}
