import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getSatLibState } from '@app/store/data/globals';

import { getSocket } from '@app/services/Socket';
import { createSocketPromiseSync } from '@app/services/SocketPromiseWrapper';

import {
  SatelliteLibraryItem,
  SatelliteStatus,
  SatelliteStatusChanges,
  SatelliteStatusItem,
  SatelliteSystem,
} from '@app/models';

import { dayjs, parse, startOfDay } from '@app/utils/Datetime';
import { Dayjs } from '@app/utils/Dayjs';
import { getSatelliteStatus } from '@app/utils/SatelliteStatus';

/* *********************** *
 *    Type definitions     *
 * *********************** */

export interface SatLibState {
  satelliteLibrary: SatelliteLibraryItem[];
  satelliteStatus: SatelliteStatusItem[];
  satelliteStatusChanges: SatelliteStatusChanges;
}

interface LibraryResponseItem {
  id_sat: number;
  cospar_id: string;
  gnss: string;
  norad_satcat: number;
  prn: string;
  launch_date: string;
  name: string;
  nickname: string;
  remarks: string;
  longTermUnhealthy: boolean;
  status: 'operational' | 'not_usable' | 'testing' | 'retired' | 'deleteme' | 'experiment';
  clock: string;
  plane: string;
  slot: string;
  block_type: string;
  signals: string;
}

interface StatusResponseItem {
  date: string;
  status: { [prn: string]: '0' | '1' | 'None' | null };
}

interface StatusChangeResponseItem {
  [prn: string]: { [isoDate: string]: string };
}

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: SatLibState = {
  satelliteLibrary: [],
  satelliteStatus: [],
  satelliteStatusChanges: {},
};

const satLibSlice = createSlice({
  name: 'satLib',
  initialState,
  reducers: {
    setSatLibrary: (state: SatLibState, action: PayloadAction<SatelliteLibraryItem[]>) => {
      state.satelliteLibrary = action.payload;
    },
    setSatStatus: (state: SatLibState, action: PayloadAction<SatelliteStatusItem[]>) => {
      state.satelliteStatus = action.payload;
    },
    setSatelliteStatusChanges: (
      state: SatLibState,
      action: PayloadAction<SatelliteStatusChanges>,
    ) => {
      state.satelliteStatusChanges = action.payload;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const getSatLibrary = (state: RootState) => getSatLibState(state).satelliteLibrary;
export const getSatStatus = (state: RootState) => getSatLibState(state).satelliteStatus;
export const getSatelliteStatusChanges = (state: RootState) =>
  getSatLibState(state).satelliteStatusChanges;

export const getLatestSatelliteStatus = createSelector(
  [getSatLibrary, getSatStatus, getSatelliteStatusChanges],
  (satellites, healthStatus, healthStatusChanges) => {
    const initial: { [key: string]: SatelliteStatus } = {};
    const last = healthStatus[healthStatus.length - 1];
    let date: Dayjs = dayjs();
    if (last) {
      date = dayjs(last.date);

      const latest = satellites.reduce((acc, satellite) => {
        let status = last.status[satellite.prn];
        if (
          [SatelliteStatus.EXPERIMENT, SatelliteStatus.RETIRED, SatelliteStatus.TESTING].includes(
            satellite.status,
          )
        ) {
          status = satellite.status;
        } else if (status === SatelliteStatus.MARGINAL) {
          const changes = Object.entries(healthStatusChanges[satellite.prn])
            .map((e) => ({
              date: parse(e[0]),
              status: e[1],
            }))
            .filter((e) => date.isSame(e.date, 'day'));
          changes.sort((a, b) => (a.date > b.date ? -1 : 1));
          if (changes.length > 0) {
            // we found some changes for this satellite, so we take the newest information
            status = getSatelliteStatus(changes[0].status);
          } else {
            // no changes found, so we set status to unhealty
            status = SatelliteStatus.UNHEALTHY;
          }
        }
        acc[satellite.prn] = status;
        return acc;
      }, initial);

      return { date, status: latest };
    }

    return { date, status: initial };
  },
);

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setSatLibrary, setSatStatus, setSatelliteStatusChanges } = satLibSlice.actions;

export const querySatLibrary = createAsyncThunk('data/satlib/query', async (args, thunkApi) => {
  const socket = getSocket();
  const socketPromise = createSocketPromiseSync(socket);

  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  const response = await socketPromise<LibraryResponseItem[]>('getSatelliteLibrary', {}, 60000); // TODO useful timeout
  // query data and transform response

  const satLibArray = response.map((item) => ({
    id: item.id_sat,
    constellation: item.gnss as SatelliteSystem,
    prn: item.prn,
    name: item.name,
    nickname: item.nickname,
    norad: item.norad_satcat,
    cospar: item.cospar_id,
    launchDate: item.launch_date,
    status: getSatelliteStatus(item.status),
    remarks: item.remarks,
    longTermUnhealthy: item.longTermUnhealthy,
    clock: item.clock,
    plane: item.clock,
    slot: item.slot,
    blockType: item.block_type,
    signals: item.signals,
  }));

  thunkApi.dispatch(setSatLibrary(satLibArray));

  return satLibArray;
});

export const querySatStatus = createAsyncThunk('data/satstatus/query', async (args, thunkApi) => {
  const socket = getSocket();
  const socketPromise = createSocketPromiseSync(socket);

  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  const now = startOfDay();

  const response = await socketPromise<StatusResponseItem[]>(
    'getSatelliteStatus',
    { date_start: now.subtract(14, 'day').toISOString(), date_end: now.toISOString() },
    60000,
  ); // TODO useful timeout

  const satStatusArray = response.map((item) => ({
    ...item,
    status: Object.entries(item.status).reduce(
      (acc, cur) => ({ ...acc, [cur[0]]: getSatelliteStatus(cur[1]?.toLowerCase() || 'None') }),
      {},
    ),
  })) as SatelliteStatusItem[];

  satStatusArray.sort((a, b) => (dayjs(a.date).isBefore(b.date) ? -1 : 1));

  thunkApi.dispatch(setSatStatus(satStatusArray));

  return satStatusArray;
});

/**
 * queryPreciseSatelliteStatus createAsyncThunk
 */
export const querySatelliteStatusChanges = createAsyncThunk(
  'data/getSatelliteStatusChanges/query',
  async (args, thunkApi) => {
    const socket = getSocket();
    const socketPromise = createSocketPromiseSync(socket);

    if (socketPromise == null) {
      return Promise.reject(new Error('No valid socket object!'));
    }
    const now = startOfDay();

    const response = await socketPromise<StatusChangeResponseItem>(
      'getSatelliteStatusChanges',
      { date_start: now.subtract(14, 'day').toISOString(), date_end: now.toISOString() },
      10000,
    ); // TODO useful timeout
    const satelliteStatusChanges = response as SatelliteStatusChanges;
    thunkApi.dispatch(setSatelliteStatusChanges(satelliteStatusChanges));

    return satelliteStatusChanges;
  },
);

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default satLibSlice.reducer;
