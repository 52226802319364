import React from 'react';

import { Box, CircularProgress } from '@mui/material';

export interface CircularLoadingProps {
  isLoading: boolean;
  size?: 'small' | 'normal' | 'large';
  overlay?: boolean;
  children?: JSX.Element | null;
}

const SIZES = {
  small: '3rem',
  normal: '50%',
  large: '100%',
};

const CircularLoading = ({
  isLoading,
  size = 'normal',
  overlay = false,
  children = null,
}: CircularLoadingProps) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',

          '&.overlay': {
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 1000,
          },
        }}
        className={`${overlay ? 'overlay' : ''}`}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return children;
};

export default CircularLoading;
