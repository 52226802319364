/**
 * This file provides a promise wrapper for socket  events.
 *
 * It can be used to await a response in a thunk.
 *
 * The awaited socket response should have the request event name with '_Response' added to it as event name
 *
 */
import { Socket } from 'socket.io-client';

export function createSocketPromiseSync(socket: Socket) {
  // check for valid socket
  if (!socket) {
    return null;
  }

  return function socketPromise<ResponseType>(
    eventName: string,
    queryDict: Record<string, unknown>,
    ms: number,
  ) {
    let resolve: any;
    let reject: any;

    const promise = new Promise<ResponseType>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    socket.emit(eventName, queryDict, (response: ResponseType) => {
      if (!response) {
        reject(
          `Missing response from socketio callback for request: ${eventName}, ${JSON.stringify(
            queryDict,
          )}`,
        );
      }
      resolve(response);
    });

    // TODO handle timeouts, however, what is a reasonable time for timeouts
    setTimeout(() => {
      reject(new Error('Timed out'));
    }, ms);

    return promise;
  };
}

export default function createSocketPromise(socket: Socket) {
  // check for valid socket
  if (!socket) {
    return null;
  }

  return function socketPromise<ResponseType>(
    eventName: string,
    queryDict: Record<string, unknown>,
    ms: number,
  ) {
    let resolve: any;
    let reject: any;

    const promise = new Promise<ResponseType>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    socket.emit(eventName, queryDict);

    socket.on(eventName.concat('_Response'), (response: ResponseType) => {
      if (!response) {
        reject(
          `Missing response from socketio callback for request: ${eventName}, ${JSON.stringify(
            queryDict,
          )}`,
        );
      }
      resolve(response);
    });

    // TODO handle timeouts, however, what is a reasonable time for timeouts
    const timeout = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Timed out'));
      }, ms);
    });

    return Promise.race([promise, timeout]);
  };
}
