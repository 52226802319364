import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getStationsState } from '@app/store/data/globals';

import { getSocket } from '@app/services/Socket';
import { createSocketPromiseSync } from '@app/services/SocketPromiseWrapper';

import { CustomStation, RealStation, StationType } from '@app/models';

import { xyz2Position } from '@app/utils/CoordinateSystems';
import { fixed } from '@app/utils/Math';
import { Vector } from '@app/utils/Vector';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface StationsState {
  stations: RealStation[];
  custom: CustomStation[];
  editing: CustomStation | null;
}

interface ResponseItem {
  city: string;
  country: string;
  height: number;
  latitude: number;
  longitude: number;
  state: string;
  station: string;
  tectonicplate: string;
  xcoordinate: number;
  ycoordinate: number;
  zcoordinate: number;
  xvelocity: number;
  yvelocity: number;
  zvelocity: number;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */
export const createCustomStation = (latlng?: L.LatLng): CustomStation => ({
  id: 'new',
  type: StationType.Custom,
  label: 'Default Marker',
  position: {
    latitude: Number.parseFloat(fixed(latlng?.lat, 6)) || 0,
    longitude: Number.parseFloat(fixed(latlng?.lng, 6)) || 0,
    height: 0,
  },
});

export const isSaved = (station: CustomStation) => station.id !== 'new';

export const isRealStation = (station: RealStation | CustomStation) =>
  station.type === StationType.Real;
export const isCustomStation = (station: RealStation | CustomStation) =>
  station.type === StationType.Custom;

export const queryStations = createAsyncThunk('data/stations/load', async (arg, thunkApi) => {
  const socket = getSocket();
  const socketPromise = createSocketPromiseSync(socket);

  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  const response = await socketPromise<ResponseItem[]>('getStations', {}, 60000); // TODO useful timeout

  const msToYears = 31557600000; // 1000 * 60 * 60 * 24 * 256.25
  const timeDelta = (new Date().getTime() - new Date('2010-01-01').getTime()) / msToYears;

  const stations = response.map((station) => {
    const updatedCoordinates: Vector = {
      x: station.xcoordinate + timeDelta * station.xvelocity,
      y: station.ycoordinate + timeDelta * station.yvelocity,
      z: station.zcoordinate + timeDelta * station.zvelocity,
    };

    const updatedPosition = xyz2Position(updatedCoordinates);

    return {
      id: station.station,
      type: StationType.Real,
      label: `(${station.station}) ${station.city}`,
      city: station.city,
      state: station.state,
      country: station.country,
      tectonicplate: station.tectonicplate,
      position: updatedPosition,
      coordinates: updatedCoordinates,
      velocity: {
        x: station.xvelocity,
        y: station.yvelocity,
        z: station.zvelocity,
      },
    };
  });

  return stations;
});

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: StationsState = {
  stations: [],
  custom: [],
  editing: null,
};

const stationsSlice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    setStations: (state: StationsState, action: PayloadAction<RealStation[]>) => {
      state.stations = action.payload;
    },
    addCustomStation: (state: StationsState, action: PayloadAction<CustomStation>) => {
      state.custom.push(action.payload);
    },
    removeCustomStation: (state: StationsState, action: PayloadAction<CustomStation>) => {
      console.log('remove custom station', action.payload);
      state.custom = state.custom.filter((station) => station.id !== action.payload.id);
      return state;
    },
    updateCustomStation: (state: StationsState, action: PayloadAction<CustomStation>) => {
      const index = state.custom.findIndex((station) => station.id === action.payload.id);
      state.custom[index] = action.payload;
    },
    editCustomStation: (state: StationsState, action: PayloadAction<CustomStation | null>) => {
      state.editing = action.payload;
    },
  },
  extraReducers: {
    [queryStations.fulfilled.type]: (
      state: StationsState,
      action: PayloadAction<RealStation[]>,
    ) => {
      state.stations = action.payload;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const getRealStations = (state: RootState) => getStationsState(state).stations;

export const getCustomStations = (state: RootState) => getStationsState(state).custom;

export const getEditStation = (state: RootState) => getStationsState(state).editing;

export const getStations = createSelector(
  [getCustomStations, getRealStations],
  (stations, custom) => [...custom, ...stations],
);

/* ********************* *
 *    Export actions     *
 * ********************* */
export const {
  setStations,
  addCustomStation,
  updateCustomStation,
  removeCustomStation,
  editCustomStation,
} = stationsSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default stationsSlice.reducer;
