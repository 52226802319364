import { CombinedState, combineReducers, Reducer } from 'redux';

import snrReducer, { CNRState } from '@app/store/data/Cnr';
import metadataReducer, { MetadataState } from '@app/store/data/Metadata';
import positionAccuracyReducer, { PositionAccuracyState } from '@app/store/data/PositionAccuracy';
import satelliteClockAccuracyReducer, {
  SatelliteClockAccuracyState,
} from '@app/store/data/SatelliteClockAccuracy';
import satLibReducer, { SatLibState } from '@app/store/data/SatLibrary';
import stationsReducer, { StationsState } from '@app/store/data/Stations';
import tecReducer, { TECState } from '@app/store/data/Tec';
import tleReducer, { TLEState } from '@app/store/data/Tle';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface DataState {
  tle: TLEState;
  stations: StationsState;
  cnr: CNRState;
  tec: TECState;
  satLib: SatLibState;
  positionAccuracy: PositionAccuracyState;
  satelliteClockAccuracy: SatelliteClockAccuracyState;
  metadata: MetadataState;
}

/* ********************* *
 *    Define reducer     *
 * ********************* */
const dataReducer: Reducer<CombinedState<DataState>> = combineReducers({
  tle: tleReducer,
  stations: stationsReducer,
  cnr: snrReducer,
  tec: tecReducer,
  satLib: satLibReducer,
  positionAccuracy: positionAccuracyReducer,
  satelliteClockAccuracy: satelliteClockAccuracyReducer,
  metadata: metadataReducer,
});

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default dataReducer;
