import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

export const locale = 'en-gb';
export type Dayjs = dayjs.Dayjs;

dayjs.extend(utc);
// dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.locale(locale);

const utcWrapper = (dateType?: string | number | Date | Dayjs, format?: string): Dayjs =>
  dayjs.utc(dateType, format);
export { utcWrapper as utc };

export default dayjs;
