import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getPositionAccuracyState } from '@app/store/data/globals';

import { getSocket } from '@app/services/Socket';
import { createSocketPromiseSync } from '@app/services/SocketPromiseWrapper';

import { Position, RealStation, SatelliteSystem } from '@app/models';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface PositionAccuracyPoint extends Position {
  date: string;
}

interface PositionAccuracyMap {
  [SatelliteSystem.GALILEO]: PositionAccuracyPoint[];
  [SatelliteSystem.GPS]: PositionAccuracyPoint[];
  [SatelliteSystem.GLONASS]: PositionAccuracyPoint[];
  [SatelliteSystem.BEIDOU]: PositionAccuracyPoint[];
}

export interface PositionAccuracyState {
  isLoading: boolean;
  hasError: boolean;
  station: RealStation | null;
  date: number | null;
  data: PositionAccuracyMap | null;
  requests: number;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

/**
 * This function calls our api and is used by a async thunk
 *
 * @param {object} queryDict (contains query parameters for the database access)
 * @param {function} getState (redux function returning whole state tree. It actually does not need to be passed when dispatching, no idea why... :( )
 * @param {string} requestID (id of this thunk call. Apparently this is also passed automatically when dispatching, again no idea why...)
 *
 * @returns {Promise} data
 */
async function getPositionAccuracyData(
  queryDict: { [index: string]: any },
  { getState, requestID }: { [index: string]: any },
) {
  const socket = getSocket();

  const eventName = 'getPosAccuracyData';
  const { station, date } = queryDict;
  const timestampMs = date;

  const parameters = {
    date,
    station: station.id,
  };

  const socketPromise = createSocketPromiseSync(socket);

  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  let data = null;

  try {
    data = await socketPromise(eventName, parameters, 50000); // TODO useful timeout
  } catch (e) {
    console.log(e);
  }

  return [station, timestampMs, data];
}

/**
 * Here we define our asyncthunk passing it a action type and a async function returning a promise
 *
 */
export const queryPositionAccuracyData = createAsyncThunk(
  'data/positionAccuracyData/get',
  getPositionAccuracyData,
);

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: PositionAccuracyState = {
  isLoading: false,
  hasError: false,
  data: null,
  station: null,
  date: null,
  requests: 0,
};

const positionAccuracySlice = createSlice({
  name: 'positionAccuracyData',
  initialState,
  reducers: {
    setPositionAccuracyMap: (state: PositionAccuracyState, action: { [index: string]: any }) => {
      const newState = { ...state };
      newState.data = action.payload;
      return newState;
    },
    reset: (state: PositionAccuracyState) => {
      state.station = null;
      state.date = null;
      state.data = null;
      state.hasError = false;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [queryPositionAccuracyData.pending.type]: (state: PositionAccuracyState) => {
      state.isLoading = true;
      state.hasError = false;
      state.requests += 1;
    },
    [queryPositionAccuracyData.fulfilled.type]: (
      state: PositionAccuracyState,
      action: PayloadAction<[RealStation, number, { [index: string]: any }]>,
    ) => {
      state.requests -= 1;
      if (state.requests > 0) {
        return;
      }
      const [station, date, data] = action.payload;
      state.station = station;
      state.date = date;
      state.data = {
        [SatelliteSystem.GALILEO]: data.GALILEO || null,
        [SatelliteSystem.GPS]: data.GPS || null,
        [SatelliteSystem.BEIDOU]: data.BEIDOU || null,
        [SatelliteSystem.GLONASS]: data.GLONASS || null,
      };
      state.isLoading = false;
      state.hasError = false;
    },
    [queryPositionAccuracyData.rejected.type]: (state: PositionAccuracyState) => {
      state.requests -= 1;
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const selectPositionAccuracy = (state: RootState) => getPositionAccuracyState(state).data;
export const selectPositionAccuracyStation = (state: RootState) =>
  getPositionAccuracyState(state).station;
export const selectPositionAccuracyDate = (state: RootState) =>
  getPositionAccuracyState(state).date;

export const isLoadingPositionAccuracy = (state: RootState) =>
  getPositionAccuracyState(state).isLoading;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setPositionAccuracyMap } = positionAccuracySlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default positionAccuracySlice.reducer;
