import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getIndicatorState } from '@app/store/globals';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface IndicatorState {
  isLoadingDopLayer: boolean;
  isLoadingSatCountLayer: boolean;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: IndicatorState = {
  isLoadingDopLayer: false,
  isLoadingSatCountLayer: false,
};

const indicatorSlice = createSlice({
  name: 'indicator',
  initialState,
  reducers: {
    setIsLoadingDopLayer: (state: IndicatorState, action: PayloadAction<boolean>) => {
      state.isLoadingDopLayer = action.payload;
    },
    setIsLoadingSatCountLayer: (state: IndicatorState, action: PayloadAction<boolean>) => {
      state.isLoadingSatCountLayer = action.payload;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const getIsLoadingDopLayer = (state: RootState) =>
  getIndicatorState(state).isLoadingDopLayer;
export const getIsLoadingSatCountLayer = (state: RootState) =>
  getIndicatorState(state).isLoadingSatCountLayer;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setIsLoadingDopLayer, setIsLoadingSatCountLayer } = indicatorSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default indicatorSlice.reducer;
