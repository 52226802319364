import dayjs, { Dayjs, utc } from './Dayjs';

export type { Dayjs };
export { dayjs, utc };

export type TimeExpressionUnitShort = 'm' | 'h' | 'd' | 'W' | 'M';
export type TimeExpressionUnitLong = 'minute' | 'hour' | 'day' | 'week' | 'month';

export interface TimeExpression {
  unit: TimeExpressionUnitLong;
  value: number;
}

export interface DateTimeInterval<T> {
  start: T;
  end: T;
}

export const stringToTimeExpression = (expression: string): TimeExpression => {
  const mapping: Record<TimeExpressionUnitShort, TimeExpressionUnitLong> = {
    m: 'minute',
    h: 'hour',
    d: 'day',
    W: 'week',
    M: 'month',
  };
  const unit = expression[expression.length - 1] as TimeExpressionUnitShort;
  const value = parseFloat(expression.substring(0, expression.length - 1));

  return {
    unit: mapping[unit],
    value,
  };
};

export const timeExpressionToMinutes = (expression: TimeExpression): number => {
  const multiplier: Record<TimeExpressionUnitLong, number> = {
    minute: 1,
    hour: 60,
    day: 60 * 24,
    week: 60 * 24 * 7,
    month: 60 * 24 * 7 * 30,
  };

  return expression.value * multiplier[expression.unit];
};

export const closestToTimeExpression = (
  date: Dayjs,
  expression: TimeExpression,
  zero = false,
): Dayjs => {
  let closest = date;
  let delta: number;

  if (zero) {
    closest = closest.startOf('minute');
  }

  switch (expression.unit) {
    case 'minute':
      delta = date.minute() % expression.value;
      closest = date.subtract(delta, 'minute');
      break;

    case 'hour':
      delta = date.hour() % expression.value;
      closest = date.startOf('minute').subtract(delta, 'hour');
      break;

    case 'day':
      delta = date.minute() % expression.value;
      closest = date.subtract(delta, 'minute');
      break;

    case 'week':
      delta = date.minute() % expression.value;
      closest = date.subtract(delta, 'minute');
      break;

    case 'month':
      delta = date.minute() % expression.value;
      closest = date.subtract(delta, 'minute');
      break;
    default:
      break;
  }

  return closest;
};

export const now = (): Dayjs => utc();
export const parse = (iso: string): Dayjs => utc(iso);
export const format = (date: string | number | Date | Dayjs, format: string) =>
  utc(date).format(format);

const startOf = (date: Dayjs | undefined, unit: 'hour' | 'day' | 'week' | 'month') => {
  if (!date) {
    date = now();
  }
  return date.startOf(unit);
};

export const startOfHour = (date?: Dayjs): Dayjs => startOf(date, 'hour');
export const startOfDay = (date?: Dayjs): Dayjs => startOf(date, 'day');
export const startOfWeek = (date?: Dayjs): Dayjs => startOf(date, 'week');
export const startOfMonth = (date?: Dayjs): Dayjs => startOf(date, 'month');

const endOf = (date: Dayjs | undefined, unit: 'hour' | 'day' | 'week' | 'month') => {
  if (!date) {
    date = now();
  }
  return date.endOf(unit);
};

export const endOfHour = (date?: Dayjs): Dayjs => endOf(date, 'hour');
export const endOfDay = (date?: Dayjs): Dayjs => endOf(date, 'day');
export const endOfWeek = (date?: Dayjs): Dayjs => endOf(date, 'week');
export const endOfMonth = (date?: Dayjs): Dayjs => endOf(date, 'month');

export const differenceIn = (start: Dayjs, end: Dayjs, unit: TimeExpressionUnitLong): number => {
  return start.diff(end, unit);
};

export const eachDateInInterval = (start: Dayjs, end: Dayjs, step: TimeExpression): Dayjs[] => {
  const dates: Dayjs[] = [];
  let current = start;

  while (current.valueOf() <= end.valueOf()) {
    dates.push(current);
    current = current.add(step.value, step.unit);
  }

  return dates;
};

// Use this conversion with caution, it desregards leap seconds
export const mjdFromUnixMilliseconds = (milliseconds: any) => milliseconds / 86400000.0 + 40587.0;

export const dateToJulian = (date: Date): number =>
  date.valueOf() / (1000 * 60 * 60 * 24) - 0.5 + 2440588;

export const julianToDate = (jd: number): Date =>
  new Date((jd + 0.5 - 2440588) * (1000 * 60 * 60 * 24));
