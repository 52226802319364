import { EcfVec3, LookAngles, SatRec } from 'satellite.js';

import { Vector } from '@app/utils/Vector';

export enum SatelliteSystem {
  GALILEO = 'Galileo',
  GPS = 'GPS',
  GLONASS = 'GLONASS',
  BEIDOU = 'BeiDou',
}

export enum SatelliteStatus {
  HEALTHY = 'operational',
  UNHEALTHY = 'not usable',
  MARGINAL = 'marginal',
  PARTIAL_UNHEALTHY = 'partly not usable',
  TESTING = 'testing',
  RETIRED = 'retired',
  EXPERIMENT = 'experiment',
  NONE = 'none',
}

export enum DOPType {
  GDOP = 'Geometric',
  PDOP = 'Position',
  HDOP = 'Horizontal',
  VDOP = 'Vertical',
  TDOP = 'Time',
}

export enum TEC {
  ASSIMILATED = 'assimilated',
  MODEL = 'model',
  ERROR = 'error',
}

export interface Position {
  latitude: number;
  longitude: number;
  height?: number;
}

export interface Satellite {
  id: string;
  record: SatRec;
  constellation: SatelliteSystem;
  prn: string;
  name?: string;
  position?: Position;
  status?: SatelliteStatus;
}

export interface VisibleSatellite extends Satellite {
  lookAngles: LookAngles;
  position: Position;
  ecf?: EcfVec3<unknown>;
}

export interface SatelliteLibraryItem {
  id: number;
  constellation: SatelliteSystem;
  prn: string;
  name: string;
  nickname: string;
  norad: number;
  cospar: string;
  launchDate: string;
  remarks: string;
  status: SatelliteStatus;
  longTermUnhealthy: boolean;
  clock: string;
  plane: string | null;
  slot: string | null;
  blockType: string;
  signals: string;
}

export interface SatelliteStatusItem {
  date: string;
  status: { [prn: string]: SatelliteStatus };
}

export interface StatusChangesPerEpoch {
  [epoch: string]: string;
}

export interface SatelliteStatusChanges {
  [satellitePrn: string]: StatusChangesPerEpoch;
}

export enum StationType {
  Real,
  Custom,
}
export interface Station {
  id: string;
  label: string;
  position: Required<Omit<Position, 'elevation'>>;
  type: StationType;
}

export interface RealStation extends Station {
  city: string;
  state: string;
  country: string;
  tectonicplate: string;
  coordinates: Vector;
  velocity: Vector;
}

export type CustomStation = Station;
