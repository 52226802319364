import { createTheme } from '@mui/material';

/* extend material ui theme properties to include satellite systems */
declare module '@mui/material/styles' {
  interface Theme {
    galileo: {
      main: React.CSSProperties['color'];
      contrastText: React.CSSProperties['color'];
    };
    gps: {
      main: React.CSSProperties['color'];
      contrastText: React.CSSProperties['color'];
    };
    glonass: {
      main: React.CSSProperties['color'];
      contrastText: React.CSSProperties['color'];
    };
    beidou: {
      main: React.CSSProperties['color'];
      contrastText: React.CSSProperties['color'];
    };
    station: {
      default: React.CSSProperties['color'];
      active: React.CSSProperties['color'];
    };
    headerHeight: number;
    timelineHeight: number;
    footerHeight: number;
    sidebarWidth: number;
    navigationHeight: number;
    navigationItemMinWidth: number;
    menuWidth: number;
  }

  interface Palette {
    galileo: Palette['primary'];
    gps: Palette['primary'];
    glonass: Palette['primary'];
    beidou: Palette['primary'];
  }

  interface PaletteOptions {
    galileo: PaletteOptions['primary'];
    gps: PaletteOptions['primary'];
    glonass: PaletteOptions['primary'];
    beidou: PaletteOptions['primary'];
  }

  interface ThemeOptions {
    galileo: {
      main: React.CSSProperties['color'];
      contrast: React.CSSProperties['color'];
    };
    gps: {
      main: React.CSSProperties['color'];
      contrast: React.CSSProperties['color'];
    };
    glonass: {
      main: React.CSSProperties['color'];
      contrast: React.CSSProperties['color'];
    };
    beidou: {
      main: React.CSSProperties['color'];
      contrast: React.CSSProperties['color'];
    };
    station: {
      default: React.CSSProperties['color'];
      active: React.CSSProperties['color'];
    };
    headerHeight: number;
    timelineHeight: number;
    footerHeight: number;
    sidebarWidth: number;
    navigationHeight: number;
    navigationItemMinWidth: number;
    menuWidth: number;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Arial',
  },
  palette: {
    primary: {
      main: '#0067be',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    galileo: {
      main: '#003968',
      contrastText: '#ffffff',
    },
    gps: {
      main: '#EF6A00',
      contrastText: '#ffffff',
    },
    glonass: {
      main: '#8C5D2B',
      contrastText: '#ffffff',
    },
    beidou: {
      main: '#C70087',
      contrastText: '#ffffff',
    },
  },
  galileo: {
    main: '#003968',
    contrast: '#ffffff',
  },
  gps: {
    main: '#EF6A00',
    contrast: '#ffffff',
  },
  glonass: {
    main: '#8C5D2B',
    contrast: '#ffffff',
  },
  beidou: {
    main: '#C70087',
    contrast: '#ffffff',
  },
  station: {
    default: '#008083',
    active: '#fcc133',
  },
  headerHeight: 3.5,
  timelineHeight: 3.25,
  footerHeight: 1.5,
  sidebarWidth: 25,
  navigationHeight: 2.5,
  navigationItemMinWidth: 3,
  menuWidth: 20,
});

export default theme;
