import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getTECState } from '@app/store/data/globals';

import { getSocket } from '@app/services/Socket';

/* *********************** *
 *    Type definitions     *
 * *********************** */

export interface GridElement {
  vtec_assimilated_tecu: number;
  vtec_rms_tecu: number;
  vtec_model_tecu: number;
}

interface QueryDict {
  date: string;
}

export interface TECState {
  tecGrid: GridElement[][] | null;
  tecIsAvailable: boolean;
  tecDataQuality: 'normal' | 'low' | undefined;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

export function queryTECGrid(dispatch: Dispatch, queryDict: QueryDict) {
  const socket = getSocket();

  // query data and transform response
  socket.emit('getTECGrid', queryDict, (response: GridElement[][] | undefined) => {
    if (response) {
      const maxError = Math.max(
        ...response.map((r) => Math.max(...r.map((el) => el.vtec_rms_tecu))),
      );
      dispatch(setTecDataQuality(maxError > 13 ? 'low' : 'normal'));
    }
    dispatch(setTECGrid(response === undefined ? null : response));
    dispatch(setTecIsAvailable(response !== undefined));
  });
}

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: TECState = {
  tecGrid: null,
  tecIsAvailable: false,
  tecDataQuality: undefined,
};

const tecSlice = createSlice({
  name: 'tec',
  initialState,
  reducers: {
    setTECGrid: (state: TECState, action: PayloadAction<GridElement[][] | null>) => {
      state.tecGrid = action.payload;
    },
    setTecIsAvailable: (state: TECState, action: PayloadAction<boolean>) => {
      state.tecIsAvailable = action.payload;
    },
    setTecDataQuality: (state: TECState, action: PayloadAction<'normal' | 'low' | undefined>) => {
      state.tecDataQuality = action.payload;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const getTECGrid = (state: RootState) => getTECState(state).tecGrid;
export const getTecIsAvailable = (state: RootState) => getTECState(state).tecIsAvailable;
export const getTecDataQuality = (state: RootState) => getTECState(state).tecDataQuality;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setTECGrid, setTecIsAvailable, setTecDataQuality } = tecSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default tecSlice.reducer;
