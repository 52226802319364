import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@app/store';
import { getLayerControlState } from '@app/store/globals';

import { DOPType, TEC } from '@app/models';

export enum StationSelection {
  None = 'None',
  All = 'All',
  OnlySelected = 'Only Selected',
  Data = 'With Data',
  NoData = 'Without Data',
}

export enum SatelliteSelection {
  None = 'None',
  All = 'All',
  FromStation = 'From Station',
  OnlySelected = 'Only Selected',
}

export type OverlaySelection = 'dop' | 'tec' | 'satellitecount' | 'none';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface LayerControlState {
  station: StationSelection;
  customStations: boolean;
  satellite: SatelliteSelection;
  overlay: OverlaySelection;
  dop: DOPType;
  tec: TEC;
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: LayerControlState = {
  station: StationSelection.None,
  customStations: true,
  satellite: SatelliteSelection.FromStation,
  overlay: 'none',
  dop: DOPType.GDOP,
  tec: TEC.ASSIMILATED,
};

const layercontrolSlice = createSlice({
  name: 'layercontrol',
  initialState,
  reducers: {
    updateStationSelection: (state: LayerControlState, action: PayloadAction<StationSelection>) => {
      state.station = action.payload;
    },
    toggleCustomStations: (state: LayerControlState) => {
      state.customStations = !state.customStations;
    },
    updateSatelliteSelection: (
      state: LayerControlState,
      action: PayloadAction<SatelliteSelection>,
    ) => {
      state.satellite = action.payload;
    },
    updateOverlaySelection: (state: LayerControlState, action: PayloadAction<OverlaySelection>) => {
      state.overlay = action.payload;
    },
    updateDopSelection: (state: LayerControlState, action: PayloadAction<DOPType>) => {
      state.dop = action.payload;
    },
    updateTecSelection: (state: LayerControlState, action: PayloadAction<TEC>) => {
      state.tec = action.payload;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const getStationSelection = (state: RootState) => getLayerControlState(state).station;
export const showCustomStations = (state: RootState) => getLayerControlState(state).customStations;
export const getSatelliteSelection = (state: RootState) => getLayerControlState(state).satellite;
export const getOverlaySelection = (state: RootState) => getLayerControlState(state).overlay;
export const getDopSelection = (state: RootState) => getLayerControlState(state).dop;
export const getTecSelection = (state: RootState) => getLayerControlState(state).tec;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const {
  updateStationSelection,
  updateSatelliteSelection,
  updateOverlaySelection,
  updateDopSelection,
  updateTecSelection,
  toggleCustomStations,
} = layercontrolSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default layercontrolSlice.reducer;
