import { configureStore } from '@reduxjs/toolkit';

import appReducer from '@app/store/app';
import dataReducer from '@app/store/data';
import accordionsReducer from '@app/store/ui/Accordions';
import chartsReducer from '@app/store/ui/Charts';
import indicatorReducer from '@app/store/ui/Indicator';
import layercontrolReducer from '@app/store/ui/LayerControl';
import settingsReducer from '@app/store/ui/Settings';
import timeTravelReducer from '@app/store/ui/TimeTravel';

const store = configureStore({
  reducer: {
    app: appReducer,
    settings: settingsReducer,
    data: dataReducer,
    accordions: accordionsReducer,
    timetravel: timeTravelReducer,
    charts: chartsReducer,
    layercontrol: layercontrolReducer,
    indicator: indicatorReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
