import React from 'react';

import { Typography } from '@mui/material';

import StandardPage from '@app/pages/StandardPage';

const NotFound = () => (
  <StandardPage>
    <Typography variant="h4" component="h4" align="center" color="primary">
      This pages does not exist yet!
    </Typography>
  </StandardPage>
);
export default NotFound;
