import React from 'react';

import { Box, Paper, Typography } from '@mui/material';

import { ReactComponent as SatelliteLoadingAnimation } from '@app/assets/images/loading/satellite.svg';
import { ReactComponent as TilesLoadingAnimation } from '@app/assets/images/loading/tiles-speed-100.svg';

export interface LoadingProps {
  isLoading: boolean;
  variant?: 'satellite' | 'tiles';
  size?: 'small' | 'normal' | 'large';
  overlay?: boolean;
  children?: JSX.Element | null;
  fixed?: boolean;
}

const SIZES = {
  small: '3rem',
  normal: '30%',
  large: '100%',
};

const useStyles = (size: 'small' | 'normal' | 'large') => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    '&.overlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      zIndex: 1000,
    },

    '&.fixed': {
      position: 'fixed',
    },

    '& .indicator': {
      width: SIZES[size],
      margin: 4,
      padding: 2,
      textAlign: 'center',
    },
  },
});

const Loading = ({
  isLoading,
  variant = 'satellite',
  size = 'normal',
  overlay = false,
  fixed = false,
  children = null,
}: LoadingProps) => {
  const styles = useStyles(size);

  if (isLoading) {
    if (overlay) {
      return (
        <Box sx={styles.root} className={`overlay ${fixed ? 'fixed' : ''}`}>
          <Paper elevation={5} className="indicator" sx={{ borderRadius: '1rem' }}>
            {variant == 'satellite' ? <SatelliteLoadingAnimation /> : <TilesLoadingAnimation />}
            <Typography variant="h5">Loading data... please wait</Typography>
          </Paper>
        </Box>
      );
    }

    return (
      <Box sx={styles.root}>
        {variant == 'satellite' ? (
          <SatelliteLoadingAnimation className="indicator" />
        ) : (
          <TilesLoadingAnimation className="indicator" />
        )}
      </Box>
    );
  }

  return children;
};

export default Loading;
