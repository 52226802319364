import { getSocket } from '../../services/Socket';
import createSocketPromise from '../../services/SocketPromiseWrapper';
import { getCNRState } from './globals';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@app/store';

import { SatelliteSystem } from '@app/models';

import { dayjs } from '@app/utils/Datetime';

/* *********************** *
 *    Type definitions     *
 * *********************** */
export interface CNRState {
  isLoading: boolean;
  hasError: boolean;
  cnrMap: {
    [SatelliteSystem.GALILEO]: Record<any, any> | null;
    [SatelliteSystem.GPS]: Record<any, any> | null;
    [SatelliteSystem.GLONASS]: Record<any, any> | null;
    [SatelliteSystem.BEIDOU]: Record<any, any> | null;
  };
}

/* ************************* *
 *    Utitilty functions     *
 * ************************* */

/**
 * This function calls our api and is used by a async thunk
 *
 * @param {object} queryDict (contains query parameters for the database access)
 * @param {function} getState (redux function returning whole state tree. It actually does not need to be passed when dispatching, no idea why... :( )
 * @param {string} requestID (id of this thunk call. Apparently this is also passed automatically when dispatching, again no idea why...)
 *
 * @returns {Promise} data
 */
async function getCNR(
  queryDict: { [index: string]: any },
  { getState, requestID }: { [index: string]: any },
) {
  const socket = getSocket();

  const eventName = 'getSignalStrengthPerStation';

  // get start and end time from getstate state tree
  // change this when we have dedicated values for start and end time TODO
  const mode: string = getState().timetravel.timeTravelMode;
  const end: number = getState().settings.selectedUtcTime;

  queryDict.date_end = end;

  switch (mode.toLowerCase()) {
    case 'hour':
      queryDict.date_start = end;
      break;
    case 'day':
      queryDict.date_start = dayjs(end).subtract(24, 'hour').toISOString();
      break;
    case 'week':
      queryDict.date_start = dayjs(end).subtract(84, 'hour').toISOString();
      break;
    case 'month':
      queryDict.date_start = dayjs(end).subtract(15, 'day').toISOString();
      break;
    default:
      queryDict.date_start = end;
      break;
  }

  const socketPromise = createSocketPromise(socket);

  if (socketPromise == null) {
    return Promise.reject(new Error('No valid socket object!'));
  }

  const data = await socketPromise(eventName, queryDict, 60000); // TODO useful timeout
  return data;
}

/**
 * Here we define our asyncthunk passing it a action type and a async function returning a promise
 *
 */
export const queryCNR = createAsyncThunk('data/cnr/get', getCNR);

/* ********************* *
 *    Slice creation     *
 * ********************* */
const initialState: CNRState = {
  isLoading: false,
  hasError: false,
  cnrMap: {
    [SatelliteSystem.GALILEO]: null,
    [SatelliteSystem.GPS]: null,
    [SatelliteSystem.GLONASS]: null,
    [SatelliteSystem.BEIDOU]: null,
  },
};

const cnrSlice = createSlice({
  name: 'cnr',
  initialState,
  reducers: {
    setCNRMap: (state: CNRState, action: { [index: string]: any }) => {
      const newState = { ...state };
      newState.cnrMap = action.payload;
      return newState;
    },
  },
  extraReducers: {
    [queryCNR.pending.type]: (state: CNRState) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [queryCNR.fulfilled.type]: (state: CNRState, action: { [index: string]: any }) => {
      // state.cnrMap = action.payload;
      state.cnrMap[SatelliteSystem.GALILEO] = action.payload.GALILEO || null;
      state.cnrMap[SatelliteSystem.GPS] = action.payload.GPS || null;
      state.cnrMap[SatelliteSystem.BEIDOU] = action.payload.BEIDOU || null;
      state.cnrMap[SatelliteSystem.GLONASS] = action.payload.GLONASS || null;
      state.isLoading = false;
      state.hasError = false;
    },
    [queryCNR.rejected.type]: (state: CNRState) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

/* *********************** *
 *    Export selectors     *
 * *********************** */
export const selectAllCNRs = (state: RootState) => getCNRState(state).cnrMap;

export const isLoadingCNRs = (state: RootState) => getCNRState(state).isLoading;

/* ********************* *
 *    Export actions     *
 * ********************* */
export const { setCNRMap } = cnrSlice.actions;

/* ********************* *
 *    Export reducer     *
 * ********************* */
export default cnrSlice.reducer;
